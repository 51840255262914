/* src/app/modules/gestion/cotizacion/registro-cotizacion/coti-informacion-principal/info-contacto-cliente/info-contacto-cliente.component.scss */
.contacto-cliente__circle {
  background: red;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f3f8;
  font-size: 32px;
  font-weight: bold;
  padding: 5px;
  margin: 0 auto;
}
/*# sourceMappingURL=info-contacto-cliente.component.css.map */
