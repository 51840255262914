import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbEjecutivo } from '~models/maestros';

@Injectable({
	providedIn: 'root',
})
export class TbEjecutivoService extends GenericService<TbEjecutivo, number> {
	private _url = `${environment.HOST_CONFIG}/tbejecutivos`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbEjecutivo[]> {
		return this._http.post<ApiResponse<TbEjecutivo[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
