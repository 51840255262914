<div class="container">
	<div class="rows">
		<div class="container">
			<div class="row">
				<div *ngFor="let item of getValue()" class="col-4">
					<em [class]="item.icono" [matTooltip]="item.nombre" matTooltipClass="bg-primary" [style.color]="item.color" [attr.aria-multiline]="true"></em>
				</div>
			</div>
		</div>
	</div>
</div>
