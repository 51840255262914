/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingService {
	private readonly _url = `${environment.HOST_CARGA}/scaroutinges`;

	constructor(private http: HttpClient) {}

	openingRouting(scaRouting: any): Observable<[boolean, any]> {
		return this.http.post<ApiResponse<any>>(`${this._url}/openingRouting`, scaRouting).pipe(map(({ error, data }) => [error, data]));
	}
}
