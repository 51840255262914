export class EnumNumeroCotizacion {
	/**
	 * @description NÚMERO DE COTIZACIÓN
	 */
	static get NROCOTI() {
		return {
			code: 'NROCOTI',
			value: 'nroCotizacion',
			label: 'NÚMERO DE COTIZACIÓN',
		};
	}
}
