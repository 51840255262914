<kt-portlet autocomplete="off">
	<kt-portlet-header [icon]="'fa fa-ban'">
		<ng-container ktPortletTitle class="ps-0">
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Cotización</strong>
			</h3>
			<span class="small pt-2 ps-1"> Rechazar </span>
		</ng-container>
	</kt-portlet-header>
	<ng-template></ng-template>
	<kt-portlet-body [modalScroll]="true">
		<form [formGroup]="formGroup">
			<div class="row mt-2">
				<div class="col-sm-12 col-lg-12 my-1">
					<ng-select-simple #ngMotivo [items]="motivoEstado$" [next]="observacion" [virtualScroll]="false" [required]="true" label="Mótivo de rechazo" formControlName="sacMotivoEstado">
					</ng-select-simple>
				</div>
				<div class="col-sm-12 col-lg-12 my-1">
					<text-area #observacion [next]="btnAceptar" [back]="ngMotivo" [height]="'70px'" [label]="'Observación'" formControlName="observacion"> </text-area>
				</div>
			</div>
		</form>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row">
			<div class="col-lg-7 d-flex align-items-center">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-lg-5 d-flex justify-content-end my-auto">
				<div class="col-12 col-sm-8 mb-0 my-1 d-flex justify-content-center justify-content-sm-end">
					<sc-button #btnAceptar class="me-3" color="success" [back]="observacion" [next]="btnCancelar" (onClick)="onSave()"> Guardar </sc-button>
					<sc-button #btnCancelar color="danger" [back]="btnAceptar" (onClick)="onCancel()"> Cancelar </sc-button>
				</div>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
