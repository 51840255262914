<div *ngIf="params" [ngStyle]="{ width: configuration.width ?? (column?.getActualWidth() ?? 0) + 20 + 'px' }" class="position-relative row">
	<div class="col-12">
		<input-text
			[showLabel]="false"
			[label]="configuration.label"
			[required]="configuration.required ?? false"
			[inputMask]="configuration.inputMask ?? ''"
			[textRight]="configuration.textRight ?? false"
			[maxLength]="configuration.maxLength"
			[formControl]="$any(formControl)"
			#control>
		</input-text>
	</div>
</div>
