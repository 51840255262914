/* angular:styles/component:css;20d1f960eef71b302ca98e9563b64944c4f7439784a005cee08a35a9ef983bc4;/builds/sumax/erp/sumax-erp-frontend/gen/sumax-erp-frontend-acuerdocomercial/src/app/modules/gestion/cotizacion/lista-cotizacion/print-tipo-documento-dialog/print-tipo-documento-dialog.component.ts */
.mat-chip {
  background-color: #ffffff00 !important;
  border: 1px solid #000000;
  border-radius: 4px !important;
  color: red;
  margin: 2px;
  transition: background-color 0.3s;
  font-family: "Soho Gothic Pro" !important;
}
.isInvalid {
  color: red;
}
.isValid {
  color: rgb(100 108 154);
}
.isInvalidMargin {
  margin: -3px 0px -10px;
}
.isValidMargin {
  margin: -20px 0px -10px;
}
/*# sourceMappingURL=print-tipo-documento-dialog.component.css.map */
