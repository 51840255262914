import { environment } from 'environments/environment';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { Injectable } from '@angular/core';
import { map, shareReplay, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TbCliente, TbEntidad } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { HttpClient } from '@angular/common/http';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class TbClienteService extends GenericService<TbCliente, number> {
	private _url = `${environment.HOST_MAESTROS}/tbclientes`;

	private _dataSelectTbEntidad$?: Observable<NgSelectOption<Required<TbEntidad>>[]>;
	private _dataSelect$?: Observable<NgSelectOption<Required<TbCliente>>[]>;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findById(id: number): Observable<TbCliente> {
		return this._http.get<ApiResponse<TbCliente>>(`${this._url}/model-se-ro/${id}`).pipe(map((res) => res.data));
	}

	findAllByIdList(ids: number[]): Observable<TbCliente[]> {
		return this._http.post<ApiResponse<TbCliente[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbCliente[]> {
		return this._http.get<ApiResponse<TbCliente[]>>(`${this._url}/model-se-ro/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(estado: boolean): Observable<INgSelectObject<TbCliente>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => {
				return of(
					res
						.map((e) => this.convertToNgSelect(e))
						.sort((a, b) => {
							const nroDocumentoA = a.tbEntidad.nroDocumento ? +a.tbEntidad.nroDocumento : 0;
							const nroDocumentoB = b.tbEntidad.nroDocumento ? +b.tbEntidad.nroDocumento : 0;
							return nroDocumentoA - nroDocumentoB;
						})
				);
			})
		);
	}

	findAllNgSelectByEstadoEntidad(estado: boolean): Observable<INgSelectObject<TbCliente>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => {
				return of(
					res
						.map((e) => this.convertToNgSelect(e))
						.sort((a, b) => {
							const nroDocumentoA = a.tbEntidad.nroDocumento ? +a.tbEntidad.nroDocumento : 0;
							const nroDocumentoB = b.tbEntidad.nroDocumento ? +b.tbEntidad.nroDocumento : 0;
							return nroDocumentoA - nroDocumentoB;
						})
				);
			})
		);
	}

	convertToNgSelect(res: TbCliente): INgSelectObject<TbCliente> {
		return {
			...res,
			value: res.idCliente,
			label: `${res.tbEntidad.nroDocumento} - ${res.tbEntidad.razonSocial}`,
		};
	}

	convertToNgSelectEntidad(res: TbEntidad): INgSelectObject<TbEntidad> {
		return {
			...res,
			value: res.idEntidad,
			label: `${res.nroDocumento} - ${res.razonSocial}`,
		};
	}

	findAllByEstadoNgSelect(state = true): Observable<Required<TbCliente>[]> {
		return this._http.get<ApiResponse<Required<TbCliente>[]>>(`${this._url}/model-se-ro/findAllByEstadoNgSelect/${String(state)}`).pipe(map(({ data }) => data));
	}

	getSelectList(saveInMemory = true): Observable<NgSelectOption<Required<TbCliente>>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstadoNgSelect().pipe(
				map((data) =>
					data.map((item) => {
						const { tbEntidad } = item;
						const { nroDocumento = '', razonSocial = '' } = tbEntidad;
						return {
							...item,
							value: item.idCliente,
							label: `${nroDocumento} - ${razonSocial}`,
						};
					})
				),
				shareReplay(1)
			);

		return this._dataSelect$;
	}

	getSelect(e: TbCliente | null | undefined): NgSelectOption<TbCliente> | null {
		return e
			? {
					...e,
					value: e.idCliente,
					label: `${e.tbEntidad?.nroDocumento} - ${e.tbEntidad?.razonSocial}`,
			  }
			: null;
	}

	getSelectListTbEntidad(saveInMemory = true): Observable<NgSelectOption<Required<TbEntidad>>[]> {
		if (!saveInMemory) this._dataSelectTbEntidad$ = undefined;

		if (!this._dataSelectTbEntidad$)
			this._dataSelectTbEntidad$ = this.findAllByEstadoNgSelect().pipe(
				map((data) =>
					data.map((item) => {
						const { tbEntidad } = item;
						const { nroDocumento = '', razonSocial = '' } = tbEntidad;

						return {
							...item.tbEntidad,
							value: item.idCliente,
							label: `${nroDocumento} - ${razonSocial}`,
						} as NgSelectOption<Required<TbEntidad>>;
					})
				),
				shareReplay(1)
			);

		return this._dataSelectTbEntidad$;
	}

	getSelectEntidadCliente(item: TbEntidad | null | undefined): NgSelectOption<TbEntidad> | null {
		return item
			? {
					...item,
					value: item.idEntidad,
					label: `${item.nroDocumento} - ${item.razonSocial}`,
			  }
			: null;
	}
}
