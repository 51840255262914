/* src/app/modules/gestion/cotizacion/lista-cotizacion/solicitud-cotizacion-dialog/solicitud-cotizacion-dialog.component.scss */
.borde-rojo {
  border: 1px solid red !important;
}
.container-select-and-add {
  width: calc(100% - 34px);
}
.container-button {
  width: 34px;
  margin-top: 19.5px;
}
.container-button::ng-deep button {
  min-width: auto;
  padding: 0;
  border-radius: 0;
}
.icon-with-border {
  border: 2px solid white;
  border-radius: 2px;
  padding: 1px;
  width: 20px;
}
:host {
  color: #646c9a;
}
.mat-mdc-selection-list {
  height: 90px;
  overflow: auto;
  outline: none;
  border: solid 1px rgb(226, 229, 236);
  padding: 0;
  background-color: white;
}
.mat-mdc-list-option {
  height: 25px !important;
  font-size: 14px !important;
  width: 100%;
  border-bottom: solid 1px rgb(226, 229, 236);
  left: 0;
  z-index: 1;
  top: calc(3em - 32px);
}
.container-select-ingreso {
  width: calc(100% - 34px);
}
/*# sourceMappingURL=solicitud-cotizacion-dialog.component.css.map */
