import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TbEntidadContactoService } from 'app/services/maestros/tbEntidadContacto.service';
import { TbCliente, TbEntidadContacto } from '~models/maestros';
import { FormGroupCompare } from '~shared/classes/FormGroupCompare';
import { DialogData } from '~shared/interface/DialogData';
import { MessageUtilService } from '~shared/services/message-util.service';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { firstValueFrom, Observable } from 'rxjs';
import { hideLoading, showSmallLoading } from '~shared/utils/LoadingUtil';

const validatorEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

@Component({
	selector: 'app-agregar-contacto',
	templateUrl: './agregar-contacto.component.html',
	styleUrl: './agregar-contacto.component.scss',
})
export class AgregarContactoComponent implements OnInit {
	@Output() contactoActualizado = new EventEmitter<number>();

	formGroup!: UntypedFormGroup;
	o1: Record<string, unknown> = {};
	dataContactos$!: Observable<TbEntidadContacto[]>;
	idCliente: number;
	idEntidadContacto!: TbEntidadContacto | null;
	idEntidad: number | undefined;
	entidadContacto!: TbEntidadContacto;

	constructor(
		private readonly _fb: UntypedFormBuilder,
		@Inject(MAT_DIALOG_DATA) public dataDlg: DialogData<NgSelectOption<TbCliente>>,
		protected readonly _messageUtilService: MessageUtilService,
		private readonly _matDialogRef: MatDialogRef<AgregarContactoComponent>,
		private readonly _tbEntidadContactoService: TbEntidadContactoService
	) {
		this.initForm();

		this.idCliente = this.dataDlg.data?.idCliente as number;
		this.idEntidad = this.dataDlg.data?.tbEntidad.idEntidad;
	}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.formGroup = this._fb.group({
			correoElectronico: [null, [Validators.required, Validators.pattern(validatorEmail)]],
			nombre: [null],
			apellido: [null],
			telefono: [null],
		});
	}

	fillUserFields(): void {
		const correoIngresado = this.formGroup.get('correoElectronico')?.value;

		if (this.formGroup.get('correoElectronico')?.valid && correoIngresado) {
			this.dataContactos$ = this._tbEntidadContactoService.findAllByIdEntidad(this.idCliente);

			this.dataContactos$.subscribe((contactos) => {
				if (contactos && contactos.length > 0) {
					const contactoEncontrado = contactos.find((contacto) => contacto.correoElectronico === correoIngresado);

					if (contactoEncontrado) {
						this.formGroup.patchValue({
							nombre: contactoEncontrado.nombre ?? '',
							apellido: (contactoEncontrado.apellido1 ?? '') + ' ' + (contactoEncontrado.apellido2 ?? ''),
							telefono: contactoEncontrado.telefonoMovil ?? '',
						});
					} else {
						this.resetFormValues(this.formGroup);
					}
				}
			});
		}
	}

	resetFormValues(formGroup: FormGroup): void {
		formGroup.patchValue({
			nombre: '',
			apellido: '',
			telefono: '',
		});
	}

	onSave(): void {
		if (this.formGroup.valid) {
			if (this.formGroup.get('nombre')?.value && this.formGroup.get('nombre')?.value.trim() !== '') {
				this._handleNombreValid();
			} else {
				this._messageUtilService.getAlertWarningBasic('El primer carácter en el nombre tiene que ser letra o número');
			}
		}
	}

	private _handleNombreValid(): void {
		if (this.formGroup.get('apellido')?.value && this.formGroup.get('apellido')?.value.trim() !== '') {
			this._handleApellidoPaternoValid();
		} else {
			this._messageUtilService.getAlertWarningBasic('El primer carácter en el Primer Apellido tiene que ser letra o número');
		}
	}

	private async _handleApellidoPaternoValid(): Promise<void> {
		const dataContactos = await firstValueFrom(this.dataContactos$);

		// Comprobamos si dataContactos tiene elementos
		if (Array.isArray(dataContactos) && dataContactos.length > 0) {
			const idEntidadContactos = dataContactos.map((id) => id.idEntidadContacto);

			const correo = this.formGroup.get('correoElectronico')?.value;
			const contactoExistente = dataContactos.find((contacto) => contacto.correoElectronico === correo);

			if (contactoExistente) {
				if (idEntidadContactos.includes(contactoExistente.idEntidadContacto)) {
					this.idEntidadContacto = contactoExistente.idEntidadContacto as TbEntidadContacto;
					this.update();
				}
			} else {
				this.insert();
			}
		} else {
			this.idEntidadContacto = null;
			this.insert();
		}
	}

	insert(): void {
		showSmallLoading('Guardando...');
		this.entidadContacto = this.setResourceToSave('R');
		this._tbEntidadContactoService.insert(this.entidadContacto).subscribe({
			next: (res) => {
				if (res !== null) {
					hideLoading();
					this._messageUtilService.success('El contacto se ha registrado correctamente.', 'Registrado');
					this.contactoActualizado.emit(res.idEntidadContacto);
					setTimeout(() => this.onClose(res), 0);
				}
			},
			error: () => hideLoading(),
		});
	}

	update(): void {
		showSmallLoading('Actualizando...');
		this.entidadContacto = this.setResourceToSave('E');
		this._tbEntidadContactoService.update(this.entidadContacto).subscribe({
			next: (res) => {
				if (res !== null) {
					hideLoading();
					this._messageUtilService.success('El contacto se ha actualizado correctamente.', 'Actualizado');
					this.contactoActualizado.emit(res.idEntidadContacto);

					setTimeout(() => this.onClose(res), 0);
				}
			},
			error: () => hideLoading(),
		});
	}

	setResourceToSave(action: string): TbEntidadContacto {
		const formValue = this.formGroup.getRawValue();
		const entidadContacto: TbEntidadContacto = {};
		if (action === 'E') {
			entidadContacto.idEntidadContacto = this.idEntidadContacto as number;
		}

		entidadContacto.tbEntidad = entidadContacto.tbEntidad || {};
		entidadContacto.tbEntidad.idEntidad = this.dataDlg.data?.tbEntidad?.idEntidad;
		entidadContacto.estado = true;
		entidadContacto.correoElectronico = this.formGroup.get('correoElectronico')?.value;
		entidadContacto.nombre = formValue.nombre ? formValue.nombre.trim() : null;
		entidadContacto.apellido1 = formValue.apellido ? formValue.apellido.trim() : null;
		entidadContacto.telefonoMovil = formValue.telefono ? formValue.telefono.trim() : null;

		return { ...entidadContacto };
	}

	async onCancel(): Promise<void> {
		if (!this.formGroup.dirty || !FormGroupCompare.isDifferentForm(this.o1, this.formGroup.value)) return this._matDialogRef.close(null);
		if (FormGroupCompare.isDifferentForm(this.o1, this.formGroup.value)) {
			const title = this.dataDlg.data ? 'la edición' : 'el registro';
			await this._messageUtilService.getMessageQuestion(`¿Desea cancelar ${title} del contacto?`, `Los cambios realizados no se guardarán`).then((res) => {
				if (res.value) this._matDialogRef.close(null);
			});
		}
	}

	onClose(res: TbEntidadContacto): void {
		this._matDialogRef.close(res.idEntidadContacto);
	}
}
