import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map, mergeMap, shareReplay, toArray } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbEjecutivoRolPK } from '~models/config/TbEjecutivoRolPK';
import { TbEjecutivo, TbEjecutivoRol } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbEjecutivoRolService extends GenericService<TbEjecutivoRol, TbEjecutivoRolPK> {
	private _url = `${environment.HOST_CONFIG}/tbejecutivoroles`;
	private _dataSelect$?: Observable<NgSelectOption<TbEjecutivo>[]>;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<TbEjecutivoRolPK> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: TbEjecutivoRolPK): string {
				return `${pk.sciRol}/${pk.tbEjecutivo}`;
			},
		};
	}

	findAllByIdRolAndEstadoNgSelect(idRol: number, estadoEjecutivo: boolean): Observable<INgSelectObject<TbEjecutivoRol>[]> {
		return this._http.get<ApiResponse<TbEjecutivoRol[]>>(`${this._url}/model-se-ro/findAllByIdRolAndEstadoNgSelect/${idRol}/${estadoEjecutivo}`).pipe(
			map((res) => res.data),
			mergeMap((res) => res.map((e) => this.convertToNgSelect(e)).sort((a, b) => ((a.codigo ?? 0) > (b.codigo ?? 0) ? 1 : -1))),
			toArray()
		);
	}

	convertToNgSelect(res: TbEjecutivoRol): INgSelectObject<TbEjecutivoRol> {
		return {
			...res,
			value: res.tbEjecutivo?.idEjecutivo,
			label: `${res.tbEjecutivo?.tbPersona?.nombre ?? ''} ${res.tbEjecutivo?.tbPersona?.apellido1 ?? ''} ${res.tbEjecutivo?.tbPersona?.apellido2 ?? ''}`,
		};
	}

	getSelectList(idRol: number, saveInMemory = true): Observable<NgSelectOption<TbEjecutivo>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByIdRolAndEstadoNgSelect(idRol, true).pipe(
				map((data) =>
					data.map((item) => ({
						...item?.tbEjecutivo,
						value: item.tbEjecutivo?.idEjecutivo,
						label: `${item.tbEjecutivo?.tbPersona?.nombre ?? ''} ${item.tbEjecutivo?.tbPersona?.apellido1 ?? ''} ${item.tbEjecutivo?.tbPersona?.apellido2 ?? ''}`,
					}))
				),
				shareReplay(1)
			);

		return this._dataSelect$;
	}

	findAllByCodigoRolAndEstadoNgSelect(codigo: string, estadoEjecutivo: boolean): Observable<INgSelectObject<TbEjecutivo>[]> {
		return this._http.get<ApiResponse<TbEjecutivoRol[]>>(`${this._url}/model-se-ro/findAllByCodigoRolAndEstadoNgSelect/${codigo}/${estadoEjecutivo}`).pipe(
			map((res) => res.data),
			mergeMap((res) => res.map((e) => this.convertToNgSelectEjecutivo(e.tbEjecutivo ?? null)).sort((a, b) => ((a.codigo ?? 0) > (b.codigo ?? 0) ? 1 : -1))),
			toArray()
		);
	}

	convertToNgSelectEjecutivo(res: TbEjecutivo | null): INgSelectObject<TbEjecutivo> {
		return {
			...res,
			value: res?.idEjecutivo,
			label: `${res?.tbPersona?.nombre} ${res?.tbPersona?.apellido1 ?? ''} ${res?.tbPersona?.apellido2 ?? ''}`.trim(),
		};
	}

	getSelect(e: TbEjecutivo | null | undefined): NgSelectOption<TbEjecutivo> | null {
		return e
			? {
					...e,
					value: e?.idEjecutivo,
					label: `${e?.tbPersona?.nombre ?? ''} ${e?.tbPersona?.apellido1 ?? ''} ${e?.tbPersona?.apellido2 ?? ''}`,
			  }
			: null;
	}
}
