<kt-portlet>
	<kt-portlet-header icon="fa fa-briefcase fa-2x">
		<ng-container ktPortletTitle class="ps-0">
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Cotizaciones</strong>
			</h3>
			&nbsp;
			<span class="small pt-2 ps-1"> Listado </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body class="p-3">
		<div class="kt-form mb-2">
			<mat-accordion>
				<app-filtro-cotizacion-por-referencia #filtroPorReferencia (onFilter)="onFilterForReferencia($event)"></app-filtro-cotizacion-por-referencia>
				<app-filtro-cotizacion-por-criterio-busqueda #filtroPorCriterioBusqueda [filterConfig]="filterConfig" (filter)="onFilterForCriterio($event)"></app-filtro-cotizacion-por-criterio-busqueda>
			</mat-accordion>
		</div>
		<div class="row pb-4">
			<div class="col-md-12 col-lg-12 col-xl-12 pb-4">
				<sc-ag-grid-customizable
					#agGrid
					(onGridReady)="onGridReady($event[0])"
					[height]="'auto'"
					[btnAdd]="{ visible: true, disabled: false }"
					(onAddRow)="onAddRow()"
					(onEditRow)="onEditRow($event.data)"
					(onDeleteRow)="onDeleteRow($event[0], $event[1])"
					[paginationPageSize]="15"
					[domLayout]="'autoHeight'"
					[frameworkComponents]="frameworkComponents"
					[rowData]="rowData$"
					[columnDefs]="columnDefs"
					[configButtonsAction]="configButtonsAction"
					(onButtonActionHeader)="onButtonAction('ActionHeader', $event[0], null!)"
					(onButtonActionAgGrid)="onButtonAction('ActionAgGrid', $event[0], $event[1])">
				</sc-ag-grid-customizable>
			</div>
		</div>
	</kt-portlet-body>
</kt-portlet>
