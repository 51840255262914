import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, shareReplay, tap, toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { SearchTbUbicacionComercial } from '~models/dto/SearchTbUbicacionComercial';
import { TbUbicacionComercial } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class TbUbicacionComercialService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbubicacioncomerciales`;
	private readonly _COD_PAIS_NACIONAL = environment.COD_PAIS_NACIONAL;
	private _dataSelect$: Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> | undefined;
	private _dataSelectPaises$: Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> | undefined;

	constructor(private _http: HttpClient) {}

	findById(id: number): Observable<Required<TbUbicacionComercial>> {
		return this._http.get<ApiResponse<Required<TbUbicacionComercial>>>(`${this._url}/${id}`).pipe(map((res) => res.data));
	}

	findAllCustomDtoNgSelect(search: SearchTbUbicacionComercial): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.post<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllCustomDtoNgSelect`, search).pipe(map((res) => res.data));
	}

	/**
	 * @deprecated no utilizar
	 */
	findAllByEstadoNgSelect(estado = true): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllByEstadoNgSelect/${String(estado)}`).pipe(map((res) => res.data));
	}

	findAllByCodigoPaisAndEstadoCustomNgSelect(codigoPais: string, estado = true): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllByCodigoPaisAndEstadoCustom/${codigoPais}/${String(estado)}`).pipe(map((res) => res.data));
	}

	findAllByEstadoCustom(estado = true): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllByEstadoCustom/${String(estado)}`).pipe(map((res) => res.data));
	}

	//busca por estado y el nombre hace un like para traer solo los registros en relacion a los caracteres ingresados
	findAllByNombreLikeAndEstadoCustom(nombre: string, estado = true): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllByNombreLikeAndEstadoCustom/${String(nombre)}/${String(estado)}`).pipe(map((res) => res.data));
	}
	//busca por estado y el nombre hace un like para traer solo los registros en relacion a los caracteres ingresados
	findAllBySearchAndNombreLikeCustom(search: SearchTbUbicacionComercial, nombre: string): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.post<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllBySearchAndNombreLikeCustom/${String(nombre)}`, search).pipe(map((res) => res.data));
	}

	//busca por estado y (nombre o codigo) hace un like para traer solo los registros en relacion a los caracteres ingresados
	findAllBySearchAndNombreOrCodigoLikeCustom(search: SearchTbUbicacionComercial, nombre: string): Observable<Required<TbUbicacionComercial>[]> {
		return this._http.post<ApiResponse<Required<TbUbicacionComercial>[]>>(`${this._url}/findAllBySearchAndNombreOrCodigoLikeCustom/${String(nombre)}`, search).pipe(map((res) => res.data));
	}

	findAllByIdList(ids: number[]): Observable<TbUbicacionComercial[]> {
		return this._http.post<ApiResponse<TbUbicacionComercial[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllCustom(search?: SearchTbUbicacionComercial, nombre?: string): Observable<Required<TbUbicacionComercial>[]> {
		if (search) {
			const nombreUbicacion = nombre?.trim() ? nombre : '';
			if (!nombre) {
				return this.findAllCustomDtoNgSelect(search);
			} else {
				return this.findAllBySearchAndNombreOrCodigoLikeCustom(search, nombreUbicacion);
			}
		} else {
			const nombreUbicacion = nombre?.trim() ? nombre : '';
			return this.findAllByNombreLikeAndEstadoCustom(nombreUbicacion);
		}
	}

	// nombreAeropuerto?: string; - no existe
	// tbPais?: TbPais; - no existe
	getSelectList(search?: SearchTbUbicacionComercial, saveInMemory = true, nombre = ''): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllCustom(search, nombre).pipe(
				map((dataPais) =>
					dataPais.map((ubicacionComercialItems) => ({
						...ubicacionComercialItems,
						value: ubicacionComercialItems.idUbicacionComercial,
						label: `${ubicacionComercialItems.codigo} - ${ubicacionComercialItems.nombre}, ${ubicacionComercialItems.tbPais?.nombre ?? ''}`,
					}))
				),
				shareReplay(1)
			);
		}

		return this._dataSelect$;
	}

	getSelectListPaises(codigoPais: string, estado = true, saveInMemory = true): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		if (!saveInMemory) this._dataSelectPaises$ = undefined;

		if (!this._dataSelectPaises$) {
			this._dataSelectPaises$ = this.findAllByCodigoPaisAndEstadoCustomNgSelect(codigoPais, estado).pipe(
				map((data) =>
					data.map((ubicacionComercial) => ({
						...ubicacionComercial,
						value: ubicacionComercial.idUbicacionComercial,
						label: `${ubicacionComercial.codigo} - ${ubicacionComercial.nombre}, ${ubicacionComercial.tbPais?.nombre ?? ''}`,
					}))
				),
				shareReplay(1)
			);
		}

		return this._dataSelectPaises$;
	}

	findAllByCodigoPaisAndEstadoNgSelect(codigoPais: string, estado: boolean): Observable<INgSelectObject<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<TbUbicacionComercial[]>>(`${this._url}/findAllByCodigoPaisAndEstadoCustom/${codigoPais}/${estado}`).pipe(
			map((res) => res.data),
			mergeMap((data) => this.convertArrayToNgSelect(data)),
			toArray()
		);
	}

	findAllByCodigoPaisAndEstadoNgSelectFilter(codigoPais: string, estado: boolean): Observable<INgSelectObject<TbUbicacionComercial>[]> {
		return this._http.get<ApiResponse<TbUbicacionComercial[]>>(`${this._url}/findAllByCodigoPaisAndEstadoCustom/${codigoPais}/${estado}`).pipe(
			map((res) => res.data),
			mergeMap((data) => this.convertArrayToNgSelect(data)),
			toArray()
		);
	}

	convertArrayToNgSelect(data: TbUbicacionComercial[]): INgSelectObject<TbUbicacionComercial>[] {
		return data.map((ubicacionComercial) => this.convertToNgSelect(ubicacionComercial));
	}

	convertToNgSelect(res: TbUbicacionComercial): INgSelectObject<TbUbicacionComercial> {
		return {
			...res,
			value: res.idUbicacionComercial,
			label: `${res.codigo} - ${res.nombre}, ${res.tbPais?.nombre}`,
		};
	}

	getSelect(item: TbUbicacionComercial | null | undefined): NgSelectOption<TbUbicacionComercial> | null {
		return item
			? {
					...item,
					value: item.idUbicacionComercial,
					label: `${String(item.codigo)} - ${String(item.nombre)}, ${String(item.tbPais?.nombre)}`,
			  }
			: null;
	}

	getSelectListByTipoBusqueda(tipoBusqueda?: string, nombre = ''): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		const search: SearchTbUbicacionComercial = new SearchTbUbicacionComercial();
		if (tipoBusqueda) {
			search.tipoBusqueda = tipoBusqueda;
		}
		search.codigoPaisNacional = this._COD_PAIS_NACIONAL;
		search.estado = true;
		return this.getSelectList(search, false, nombre);
	}

	getSelectListByTipoContinente(codigoContinente: string): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		const search: SearchTbUbicacionComercial = new SearchTbUbicacionComercial();
		search.codigoContinente = codigoContinente;
		search.estado = true;
		return this.getSelectList(search);
	}

	getSelectListByTipoContinenteAndTipoBusqueda(codigoContinente: string, tipoBusqueda: string, codigoPaisNacional?: boolean): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		const search: SearchTbUbicacionComercial = new SearchTbUbicacionComercial();
		search.tipoBusqueda = tipoBusqueda;
		search.codigoPaisNacional = 'PE';
		search.codigoContinente = codigoContinente;
		search.estado = true;
		return this.getSelectList(search, false);
	}

	getSelectListByCodigoPais(codigoPais: string, estado = true): Observable<NgSelectOption<Required<TbUbicacionComercial>>[]> {
		return this.getSelectListPaises(codigoPais, estado, false);
	}
}
