import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '~shared/interface/DialogData';
import { TbEntidadContacto } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { DateUtil } from '~shared/utils/DateUtil';

@Component({
	selector: 'app-routing-order-nuevo',
	templateUrl: './info-contacto-cliente.component.html',
	styleUrls: ['info-contacto-cliente.component.scss'],
})
export class InfoContactoClienteComponent implements OnInit {
	dataCtcCliente!: NgSelectOption<TbEntidadContacto>;

	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData<NgSelectOption<TbEntidadContacto>>) {}

	ngOnInit(): void {
		this.dataCtcCliente = this.dialogData.data as NgSelectOption<TbEntidadContacto>;
		if (this.dataCtcCliente?.fchNacimiento) {
			this.dataCtcCliente.fchNacimiento = DateUtil.getBirthDay(this.dataCtcCliente.fchNacimiento);
		}
	}
}
