<div *ngIf="params" [ngStyle]="{ width: configuration.width ?? (column?.getActualWidth() ?? 0) + 20 + 'px' }" class="position-relative row">
	<div class="col-12">
		<ng-select-simple
			[showLabel]="false"
			addSpace
			[virtualScroll]="false"
			[label]="configuration.label"
			[required]="configuration.required ?? false"
			[formControl]="$any(formControl)"
			#control
			[items]="configuration.items">
		</ng-select-simple>
	</div>
</div>
