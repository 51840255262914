export class SearchSacCotizacion {
	constructor(
		public tipoFiltro?: string | null,
		//Por Referencia
		public tipoReferencia?: string | null,
		public ano: number | null = null,
		public nroCotizacion: string | null = null,
		//Por criterio de busqueda
		public razonSocialCliente: string | null = null,
		public tipoFecha: string | null = null,
		public fechaInicio: Date | null = null,
		public fechaFin: Date | null = null,
		public idTipoManifiesto: number[] | null = null,
		public idViaTransporte: number[] | null = null,
		public idTipoMovimientoContenedor: number[] | null = null,
		public idClientes: number[] | null = null,
		public idEjecutivoVentas: number[] | null = null,
		public idTipoEstadoCotizacion: number[] | null = null,
		public idUbicacionComercialEmbarque: number | null = null,
		public idUbicacionComercialLlegada: number | null = null,
		public idTipoOperador: number[] | null = null
	) {}
}
