import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '@core/store';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { SacMotivoEstadoService } from 'app/services/acuerdos-comerciales/sac-motivo-estado.service';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { SacTipoEstadoCotizacionService } from 'app/services/maestros-sync/acuerdos-comerciales/sacTipoEstadoCotizacion.service';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { SacMotivoEstado } from '~models/acuerdos-comerciales';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SacCotizacionEstado } from '~models/acuerdos-comerciales/SacCotizacionEstado';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';
import { DialogData } from '~shared/interface/DialogData';
import { MessageUtilService } from '~shared/services/message-util.service';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';

@Component({
	selector: 'app-rechazar-cotizacion-dialog',
	templateUrl: './rechazar-cotizacion-dialog.component.html',
})
export class RechazarCotizacionDialogComponent implements OnInit {
	formGroup!: FormGroup;
	motivoEstado$: Observable<SacMotivoEstado[]> = of([]);

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _sacMotivoEstadoService: SacMotivoEstadoService,
		private readonly _sacCotizacionEstadoService: SacCotizacionEstadoService,
		@Inject(MAT_DIALOG_DATA) private readonly _dialogData: DialogData<SacCotizacion>,
		private readonly _store: Store<AppState>,
		private readonly _sacTipoEstadoCotizacionService: SacTipoEstadoCotizacionService,
		public readonly _dialogRef: MatDialogRef<number>,
		private readonly _messageUtilService: MessageUtilService
	) {}

	ngOnInit(): void {
		this.getUsuario();
		this.initForm();
		this.initNgSelect();
	}

	initForm(): void {
		this.formGroup = this._fb.group({
			sacMotivoEstado: [null, Validators.required],
			observacion: [null, Validators.maxLength(150)],
		});
	}

	initNgSelect(): void {
		this.motivoEstado$ = this._sacMotivoEstadoService.getNgSelectByCodTipoEstadoCotizacion(EnumEstadoCotizacion.RECHAZADA);
	}

	async onSave(): Promise<void> {
		if (this.formGroup.invalid) return this.formGroup.markAllAsTouched();
		try {
			showLoading('Guardando...');
			const cotiEstado = await this.fillFormResourceToSave();
			const resp = await firstValueFrom(this._sacCotizacionEstadoService.updateEstadoForSacCotizacion(cotiEstado));
			if (resp) {
				console.log('resp', (resp as SacCotizacionEstado).idCotizacionEstado!);
				this._dialogRef.close((resp as SacCotizacionEstado).idCotizacionEstado!);
				hideLoading();
			}
		} catch (error) {
			hideLoading();
			throw new Error('Ocurrió un error al actualizar estado.');
		}
	}

	async fillFormResourceToSave(): Promise<SacCotizacionEstado> {
		try {
			const valueForm = this.formGroup.getRawValue();
			const [tipoEstado, idUsuario] = await Promise.all([firstValueFrom(this._sacTipoEstadoCotizacionService.findByCodigo(EnumEstadoCotizacion.RECHAZADA)), this.getUsuario()]);

			return {
				sacCotizacion: { idCotizacion: this._dialogData.data?.idCotizacion } as SacCotizacion,
				idTipoEstadoCotizacion: tipoEstado.idTipoEstadoCotizacion,
				sacMotivoEstado: valueForm.sacMotivoEstado,
				observacion: valueForm.observacion,
				idUsuario: idUsuario,
			};
		} catch (error) {
			throw new Error('Ocurrió un error al preparar los datos para guardar.');
		}
	}

	async getUsuario(): Promise<number> {
		return firstValueFrom(this._store.select(currentUser).pipe(map((data) => data?.id as number)));
	}

	async onCancel(): Promise<void> {
		if (this.formGroup.pristine) return this._dialogRef.close();
		await this._messageUtilService.getMessageQuestion('¿Desea cancelar el rechazo de la cotización?', 'Los cambios realizados no se guardarán').then((resp) => {
			if (resp.isConfirmed) {
				this._dialogRef.close();
			}
		});
	}
}
