import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CustomHttpClientService } from 'sumax-erp-schematics';
import { SciSistema } from '~models/config';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Observable } from 'rxjs';

const url = `${environment.HOST_MAESTROS_SYNC}/scisistemas`;

@Injectable({
	providedIn: 'root',
})
export class SciSistemaService {
	private _dataSelect$?: Observable<any[]>;

	constructor(protected http: CustomHttpClientService) {}

	findById(id: number): Observable<SciSistema> {
		return this.http.get<ApiResponse>(`${url}/${id}`).pipe(map((res) => res.data as SciSistema));
	}

	findByCodigo(codigo: string): Observable<SciSistema> {
		return this.http.get<ApiResponse>(`${url}/findByCodigo/${codigo}`).pipe(map((res) => res.data as SciSistema));
	}

	findAllByIdList(ids: number[]): Observable<SciSistema[]> {
		return this.http.post<ApiResponse<SciSistema[]>>(`${url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstadoNgSelect(estado: boolean): Observable<SciSistema[]> {
		return this.http.get<ApiResponse>(`${url}/findAllByEstadoNgSelect/${estado}`).pipe(map((res) => res.data as SciSistema[]));
	}

	getNgSelectSimple(saveInMemory = true): Observable<any[]> {
		if (!saveInMemory) {
			this._dataSelect$ = undefined;
		}
		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllByEstadoNgSelect(true).pipe(
				mergeMap((data: SciSistema[]) => {
					return data.map((res) => {
						return { label: `${res.codigo} - ${res.nombre}`, value: res.idSistema };
					});
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelect$;
	}

	findAllByIdTipoOperadorList(ids: number[]): Observable<SciSistema[]> {
		return this.http.post<ApiResponse<SciSistema[]>>(`${url}/findAllByIdTipoOperadorList`, ids).pipe(map((resp) => resp.data));
	}
}
