/* src/app/modules/gestion/cotizacion/lista-cotizacion/solicitud-cotizacion-dialog/lista-dimensiones/lista-dimensiones.component.scss */
.container-button {
  width: 34px;
  margin-top: 19.5px;
}
.container-button::ng-deep button {
  min-width: auto;
  padding: 0;
  border-radius: 0;
}
::ng-deep .disabled-control {
  background-color: rgba(182, 184, 184, 0.5) !important;
}
.kt-portlet__head-title {
  color: #48465b !important;
}
::ng-deep .fa-ruler {
  font-size: 1.5em;
}
::ng-deep .invalid-control {
  background-color: rgba(255, 0, 0, 0.3);
}
/*# sourceMappingURL=lista-dimensiones.component.css.map */
