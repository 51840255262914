import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbIncoterm } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbIncotermService extends GenericService<TbIncoterm, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbincotermes`;
	$getNgSelect: Observable<INgSelectObject<TbIncoterm>[]> = of([]);
	private _dataSelect$?: Observable<NgSelectOption<Required<TbIncoterm>>[]>;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbIncoterm[]> {
		return this._http.post<ApiResponse<TbIncoterm[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByCodigoVersionIncotermAndEstadoNgSelect(codigoVersion: string, estado: boolean): Observable<TbIncoterm[]> {
		return this._http.get<ApiResponse<TbIncoterm[]>>(`${this._url}/findAllByCodigoVersionIncotermAndEstadoNgSelect/${codigoVersion}/${estado}`).pipe(map((res) => res.data));
	}

	getNgSelectRegistroDoc(codigo: string, reload = true): Observable<INgSelectObject<TbIncoterm>[]> {
		if (!this.$getNgSelect || reload)
			this.$getNgSelect = this.findAllByCodigoVersionIncotermAndEstadoNgSelect(codigo, true).pipe(
				mergeMap((data) => {
					return data.map((res: TbIncoterm) => {
						return this.convertToNgSelect(res);
					});
				}),
				toArray(),
				shareReplay({ refCount: true })
			);
		return this.$getNgSelect;
	}

	convertToNgSelect(res: TbIncoterm): INgSelectObject<TbIncoterm> {
		return {
			...res,
			value: res.idIncoterm,
			label: `${res.codigo} - ${res.nombre}`,
		};
	}

	getSelectList(codigo: string, saveInMemory = true, addItemTodo = false): Observable<NgSelectOption<Required<TbIncoterm>>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByCodigoVersionIncotermAndEstadoNgSelect(codigo, true).pipe(
				map((data) =>
					data.map(
						(incoterm) =>
							({
								...incoterm,
								value: incoterm.idIncoterm,
								label: `${incoterm.codigo} - ${incoterm.nombre}`,
							} as NgSelectOption<Required<TbIncoterm>>)
					)
				),
				map((data) => {
					if (addItemTodo) {
						const all = { ...(<Required<TbIncoterm>>{}), value: null, label: '-TODOS-' };

						data = [all, ...data];
					}

					return data;
				}),
				shareReplay(1)
			);

		return this._dataSelect$;
	}
}
