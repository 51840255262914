import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SdoTipoDocumento } from '~models/doc-oper/SdoTipoDocumento';
import { ApiResponse } from '~shared/interface/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class SdoTipoDocumentoService {
	private readonly _url = `${environment.HOST_DOC_OPER}/sdotipodocumentos`;
	private readonly _codSistema = `${environment.COD_SISTEMA}`;
	//private readonly _url = `http://localhost:8098/dev/sumax-erp-backend-documentos-operativos/api/sdotipodocumentos`;
	constructor(private http: HttpClient) {}

	findAllByCodigoSistema(dto: SacCotizacion): Observable<SdoTipoDocumento[]> {
		return this.http.post<ApiResponse<SdoTipoDocumento[]>>(`${this._url}/model-se-ro/findAllByCodigoSistema/${this._codSistema}/${true}`, dto).pipe(map((res) => res.data));
	}
}
