import { Directive, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { AgEditorComponent } from 'ag-grid-angular';
import { Column, ICellEditorParams } from 'ag-grid-community';
import { InputTextComponent } from 'ngx-sumax-erp-component';
import { BaseConfigEditor } from '~models/components/BaseConfigEditor';
import ICellEditorParamsCustom from '~models/components/ICellEditorParamsCustom';

let nextId = 0;
@Directive({
	host: {
		'[id]': 'idGenericCellEditor',
	},
})
export abstract class GenericCellEditor<T extends BaseConfigEditor> implements AgEditorComponent {
	idGenericCellEditor = `generic-cell-editor-${++nextId}`;
	params!: ICellEditorParamsCustom<T>;
	configuration!: T;
	column?: Column;
	formControl: AbstractControl = new UntypedFormControl(null);
	@ViewChild('control') set control(v: InputTextComponent) {
		setTimeout(() => v.focus(), 150);
	}

	agInit(params: ICellEditorParams): void {
		this.params = params as ICellEditorParamsCustom<T>;
		this.column = this.params.column;
		this.configuration = { ...this.params.configuration };
		this.formControl = (params.data as UntypedFormControl).get(this.params.controlName) as any;
		this.formControl.markAllAsTouched();
	}

	getValue(): string {
		return this.formControl.value;
	}

	isCancelBeforeStart?(): boolean {
		return this.formControl.disabled || this.params.eventKey == 'Backspace' || this.params.eventKey == 'c' || this.params.eventKey == 'v';
	}

	onClick(happy: boolean) {
		this.params.api?.stopEditing();
	}

	isPopup(): boolean {
		return true;
	}

	onFocusOut(): void {
		this.params.api?.stopEditing();
		this.params = null as any;
	}
}
