import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SacTipoEstadoCotizacion } from '~models/maestros-sync/maestros/SacTipoEstadoCotizacion';

@Injectable({
	providedIn: 'root',
})
export class SacTipoEstadoCotizacionService extends GenericService<SacTipoEstadoCotizacion, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/sactipoestadocotizaciones`;

	private _dataSelect$?: Observable<SacTipoEstadoCotizacion[]>;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findByCodigo(codigo: string): Observable<SacTipoEstadoCotizacion> {
		return this._http.get<ApiResponse<SacTipoEstadoCotizacion>>(`${this._url}/findByCodigo/${codigo}`).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<SacTipoEstadoCotizacion[]> {
		return this._http.get<ApiResponse<SacTipoEstadoCotizacion[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllByIdList(ids: number[]): Observable<SacTipoEstadoCotizacion[]> {
		return this._http.post<ApiResponse<SacTipoEstadoCotizacion[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
