<kt-portlet autocomplete="off">
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<div class="me-3">
				<em class="fa fa-briefcase fa-2x"></em>
			</div>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Cotización</strong>
			</h3>
			<span class="small pt-2 ps-1"> Solicitud </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<div class="container" [formGroup]="formGroup">
			<fieldset class="fieldset-border fieldset-focus rounded">
				<legend class="fieldset-border">Datos principales</legend>
				<div class="row mt-0">
					<div class="col-lg-3"></div>
					<div class="col-lg-9"></div>
				</div>
			</fieldset>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row justify-content-md-between px-4">
			<div class="col-auto d-flex align-items-center ps-0">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-auto d-flex align-items-center flex-wrap my-auto"></div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
