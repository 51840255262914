import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { SacCotizacionConcepto } from '~models/acuerdos-comerciales';
import { GenericCrudService } from '~shared/classes/data-service/GenericCrud.service';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { UtilidadTotalDetalle } from '~shared/interface/UtilidadTotalDetalle';
import { CustomHttpClientService } from '~shared/services/custom-http-client.service';

const url = `${environment.HOST_ACUERDO_COMERCIAL}/saccotizacionconceptos`;
@Injectable({
	providedIn: 'root',
})
export class SacCotizacionConceptoService extends GenericCrudService<SacCotizacionConcepto> {
	constructor(protected _http: CustomHttpClientService) {
		super(url, _http);
	}

	findAllByIdCotizacion(idCotizacion: number): Observable<SacCotizacionConcepto[]> {
		return this._http.get<ApiResponse<SacCotizacionConcepto[]>>(`${url}/model-se-ro/findAllByIdCotizacion/${idCotizacion}`).pipe(map((res) => res.data));
	}

	findByIdCotizacionCustom(idCotizacion: number): Observable<SacCotizacionConcepto> {
		return this._http.get<ApiResponse<SacCotizacionConcepto>>(`${url}/model-se-ro/findByIdCotizacionCustom/${idCotizacion}`).pipe(map((res) => res.data));
	}

	findAllUtilidadTotal(idCotizacion: number): Observable<UtilidadTotalDetalle[]> {
		return this._http.get<ApiResponse<UtilidadTotalDetalle[]>>(`${url}/model-se-ro/findAllUtilidadTotal/${idCotizacion}`).pipe(map((resp) => resp.data));
	}

	findAllByIdList(ids: number[]): Observable<SacCotizacionConcepto[]> {
		return this._http.post<ApiResponse<SacCotizacionConcepto[]>>(`${url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
