import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdoPrinterService } from 'app/services/doc-oper/sdoPrinter.service';
import { Observable, Subscription, firstValueFrom, map, of, tap } from 'rxjs';
import { DialogData } from '~shared/interface/DialogData';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { FiltroPorReferenciaService } from '../filtro-por-referencia.service';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';
import { SdoTipoDocumento } from '~models/doc-oper/SdoTipoDocumento';
import { SacCotizacionEstado } from '~models/acuerdos-comerciales/SacCotizacionEstado';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SacCotizacionContenedor } from '~models/acuerdos-comerciales/SacCotizacionContenedor';
import { SciEmpresa } from '~models/config';
import { SdoPrinterCustom } from '~models/doc-oper/SdoPrinterCustom';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';
import { SacTipoEstadoCotizacionService } from 'app/services/maestros-sync/acuerdos-comerciales/sacTipoEstadoCotizacion.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { SdoTipoDocumentoElemento } from '~models/doc-oper/SdoTipoDocumentoElemento';
import { EnumTipoElemento } from '~shared/enums/EnumTipoElemento';
import { MessageUtilService } from '~shared/services/message-util.service';

@Component({
	selector: 'app-print-tipo-documento-dialog',
	templateUrl: './print-tipo-documento-dialog.component.html',
	styles: [
		`
			.mat-chip {
				background-color: #ffffff00 !important;
				border: 1px solid #000000;
				border-radius: 4px !important;
				color: red;
				margin: 2px;
				transition: background-color 0.3s;
				font-family: 'Soho Gothic Pro' !important;
			}
			.isInvalid {
				color: red;
			}
			.isValid {
				color: rgb(100 108 154);
			}
			.isInvalidMargin {
				margin: -3px 0px -10px;
			}
			.isValidMargin {
				margin: -20px 0px -10px;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class PrintTipoDocumentoDialogComponent implements OnInit, OnDestroy {
	formGroup!: UntypedFormGroup;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dataFormato$: Observable<NgSelectOption<any>> = of([]);
	sdoTipoDocumento!: SdoTipoDocumento;
	subscription$ = new Subscription();
	labelButton = 'Imprimir';
	isEmail = false;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dataDlg: DialogData<SdoTipoDocumento[]>,
		private readonly _sdoPrinterService: SdoPrinterService,
		private readonly _filtroPorReferenciaService: FiltroPorReferenciaService,
		private readonly _dlgRef: MatDialogRef<PrintTipoDocumentoDialogComponent>,
		private readonly _fb: UntypedFormBuilder,
		private readonly _sacCotizacionEstadoService: SacCotizacionEstadoService,
		private appStore: Store<AppState>,
		private readonly _sacTipoEstadoCotizacionService: SacTipoEstadoCotizacionService,
		private readonly _changeDetectorRef: ChangeDetectorRef,
		private readonly _messageUtilService: MessageUtilService
	) {
		this.formGroup = this._fb.group({
			formato: [null, Validators.required],
			isPdf: new UntypedFormControl(false),
			destinatarios: this._fb.array([]),
			validDestinatario: [null, Validators.required],
			conCopiaA: this._fb.array([]),
		});
	}

	ngOnInit(): void {
		this.fillForm(this.dataDlg.data!);
	}

	fillForm(formValue: SdoTipoDocumento[]): void {
		this.dataFormato$ = of(formValue).pipe(
			map((resp) =>
				resp?.map((data) => ({
					...data,
					value: data.idTipoFormato,
					label: `${data.nombre?.concat(' (' + data.tbTipoFormato?.codigo + ')')}`,
				}))
			)
		);
	}

	onChangeTipoFomato(e: NgSelectOption<SdoTipoDocumento>): void {
		this.getDestinatarios.clear();
		this.getConCopiaA.clear();
		const isEmail = e.tbTipoFormato?.codigo === 'EMAIL';
		this.sdoTipoDocumento = this.dataDlg.data?.filter((f) => f.rutaPlantilla === e.rutaPlantilla)[0] as SdoTipoDocumento;
		if (isEmail) {
			this.formGroup.get('validDestinatario')?.reset();
			this.formGroup.get('validDestinatario')?.setValidators([Validators.required]);
			this.formGroup.get('validDestinatario')?.updateValueAndValidity();

			this.labelButton = 'Enviar';
			this.isEmail = true;
			const setValidTo: string[] = [];
			e.sdoTipoDocumentoElementos?.forEach((element) => {
				const mails = element.valor?.split(';')?.map((data) => data.trim());
				mails?.forEach((mail) => {
					if (EnumTipoElemento.DESTINATARIO === element.tbTipoElemento?.codigo) {
						const control = this._fb.control(mail, Validators.email);
						this.getDestinatarios.push(control);
						setValidTo.push(element.valor!);
					} else if (EnumTipoElemento.CON_COPIA === element.tbTipoElemento?.codigo) {
						const control = this._fb.control(mail, Validators.email);
						this.getConCopiaA.push(control);
					}
				});
			});
			if (setValidTo.length != 0) {
				this.formGroup.get('validDestinatario')?.setValue(setValidTo);
			}
		} else {
			this.formGroup.get('validDestinatario')?.setErrors(null);
			this.formGroup.get('validDestinatario')?.clearValidators();

			this.isEmail = false;
			this.labelButton = 'Imprimir';
		}
		this._changeDetectorRef.markForCheck();
	}

	imprimir(): void {
		if (this.formGroup.invalid) return this.formGroup.markAllAsTouched();
		const message = this.isEmail ? 'Enviando...' : 'Generando documento...';
		showLoading(message);
		const contTipoContenedores = this.generarDetallesContenedores((this.dataDlg.object as SacCotizacion).sacCotizacionContenedores as SacCotizacionContenedor[]);
		const getCorreos = (values: string[], codTipoElemento: string): SdoTipoDocumentoElemento | null => {
			if (values.length == 0) return null;
			const correos: string[] = [];
			values.forEach((element) => {
				correos.push(element.toUpperCase().trim());
			});
			const sdoTipoDocumentoElemento: SdoTipoDocumentoElemento = {
				valor: String(values),
				tbTipoElemento: {
					codigo: codTipoElemento,
				},
			};
			return sdoTipoDocumentoElemento;
		};
		try {
			const destinatarios = getCorreos(this.formGroup.get('destinatarios')?.value, EnumTipoElemento.DESTINATARIO);
			const copias = getCorreos(this.formGroup.get('conCopiaA')?.value, EnumTipoElemento.CON_COPIA);
			const elementos: SdoTipoDocumentoElemento[] = [];
			if (destinatarios) {
				elementos.push(destinatarios);
			}
			if (copias) {
				elementos.push(copias);
			}
			const data = [
				{
					idTipoDocumento: this.sdoTipoDocumento.idTipoDocumento,
					isPdf: this.formGroup.get('isPdf')?.value,
					object: { ...this.dataDlg.object, cantidadTipoContenedores: contTipoContenedores, sciEmpresa: this.getUserIdOrEmpresa('sciEmpresa') },
					sdoTipoDocumentoElementos: elementos,
				} as SdoPrinterCustom,
			];
			this.subscription$.add(
				this._sdoPrinterService.generateFomatos(data).subscribe({
					next: (resp) => {
						resp.forEach((element) => {
							if (element.sdoTipoDocumentoDto?.tbTipoFormato?.codigo !== 'EMAIL') {
								const isPdf = this.formGroup.get('isPdf')?.value;
								const contentType = isPdf ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
								const nomPlantilla = String(element.sdoTipoDocumentoDto?.plantilla).split('.')[0];
								const nombreArchivo = isPdf ? nomPlantilla.concat('.pdf') : String(element.sdoTipoDocumentoDto?.plantilla);
								this._filtroPorReferenciaService.downloadBase64File(contentType, element.response, nombreArchivo);
							} else {
								this._messageUtilService.getAlertSucces('El correo se envio con éxito.', 'Enviado');
								void this.actualizarEstadoCotizacion();
							}
						});
						hideLoading();
						this._dlgRef.close();
					},
					error: () => hideLoading(),
				})
			);
		} catch {}
	}

	async actualizarEstadoCotizacion(): Promise<void> {
		const respEstado = await firstValueFrom(this._sacTipoEstadoCotizacionService.findAllByEstado(true));
		const [tipoEstadoCoti] = respEstado.filter((estado) => estado.codigo == EnumEstadoCotizacion.ENVIADA);
		const sacCotizacionEstado: SacCotizacionEstado = {
			sacCotizacion: {
				idCotizacion: this.dataDlg.object.idCotizacion,
			},
			idTipoEstadoCotizacion: tipoEstadoCoti.idTipoEstadoCotizacion,
			fchRegistro: new Date(),
			idUsuario: this.getUserIdOrEmpresa('idUsuario') as number,
		};
		await firstValueFrom(this._sacCotizacionEstadoService.updateEstadoForSacCotizacion(sacCotizacionEstado));
	}

	getUserIdOrEmpresa(tipoConsulta: string): number | Partial<SciEmpresa> {
		let idUsuario = 0;
		let sciEmpresa: Partial<SciEmpresa> = {};
		this.subscription$.add(
			this.appStore
				.select(currentUser)
				.pipe(
					tap((res) => {
						idUsuario = res?.id as number;
						sciEmpresa = res?.sciEmpresa as SciEmpresa;
					})
				)
				.subscribe()
		);
		if (tipoConsulta === 'idUsuario') {
			return idUsuario;
		} else {
			return sciEmpresa;
		}
	}

	generarDetallesContenedores(contenedores: SacCotizacionContenedor[]): string {
		if (!contenedores || contenedores.length === 0) {
			return '';
		}

		// Mapear y contar los tipos de contenedores
		const counts = contenedores
			.filter((c) => c.tbTipoContenedor && c.tbTipoContenedor.descripcion)
			.reduce((acc, c) => {
				const descripcion = c.tbTipoContenedor!.descripcion!;
				acc[descripcion] = (acc[descripcion] || 0) + c.cantidad!;
				return acc;
			}, {} as Record<string, number>);

		// Construir la cadena concatenada
		const containersConcat = Object.entries(counts)
			.map(([key, value]) => `${value} x ${key}`)
			.join(' / ');
		return containersConcat;
	}

	get getDestinatarios(): FormArray {
		return this.formGroup.get('destinatarios') as FormArray;
	}

	get getConCopiaA(): FormArray {
		return this.formGroup.get('conCopiaA') as FormArray;
	}

	addClipRow(event: MatChipInputEvent, control: string): void {
		const isListDestinatario = control == 'destinatarios';
		if (event) {
			const controls = this._fb.control(event.value, [Validators.required, Validators.email]);
			if (controls.valid) {
				isListDestinatario ? this.getDestinatarios.push(controls) : this.getConCopiaA.push(controls);
				event.chipInput!.clear();
			}
		}
		if (isListDestinatario && this.getDestinatarios.controls.length != 0) {
			this.formGroup.get('validDestinatario')?.setErrors(null);
		}
		this._changeDetectorRef.markForCheck();
	}

	removeCorreo(index: number, control: string): void {
		const isListDestinatario = control == 'destinatarios';
		isListDestinatario ? this.getDestinatarios.removeAt(index) : this.getConCopiaA.removeAt(index);
		if (isListDestinatario && this.getDestinatarios.controls.length == 0) {
			this.formGroup.get('validDestinatario')?.setErrors({ required: true });
			this._changeDetectorRef.markForCheck();
		}
	}

	isValid(controlName: string): boolean {
		const control = this.formGroup.get(controlName);
		return ((control?.valid && control?.touched) || !control?.touched) ?? false;
	}

	isInvalid(controlName: string): boolean {
		const control = this.formGroup.get(controlName);
		return (control?.invalid && control?.touched) ?? false;
	}

	onCancel(): void {
		this._dlgRef.close();
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}
}
