import { Injectable } from '@angular/core';
import { CotizacionStore } from './registro-cotizacion/store/cotizacion.store';

export interface ConstantesCotizacion {
	CD_PUER_MARI: string;
	CD_PUER_AERO: string;
	CD_PUER_TERR: string;
	CD_COTI_UTIL_POSI: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class CotizacionConstantesService {
	private _codigosPuerto: ConstantesCotizacion = {
		CD_PUER_MARI: 'PECLL',
		CD_PUER_AERO: 'PELIM',
		CD_PUER_TERR: 'PELIM',
		CD_COTI_UTIL_POSI: true,
	};

	constructor(private readonly _store: CotizacionStore) {
		this._store.setConstantesCotizacion(this._codigosPuerto);
	}

	getCodigoPuertos(): ConstantesCotizacion | null {
		return this._store.getConstantesCotizacion();
	}
}
