import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

import { ApiResponse } from '~shared/interface/ApiResponse';
import { Observable } from 'rxjs';
import { TbTransportista, TbEntidad } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { HttpClient } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/naming-convention
type typeEntidad = 'tbEntidad';
@Injectable({
	providedIn: 'root',
})
export class TbTransportistaService {
	private readonly _url = `${environment.HOST_MAESTROS}/tbtransportistas`;
	private _dataSelect$?: Observable<NgSelectOption<TbTransportista>[]> | Observable<NgSelectOption<TbEntidad>[]>;

	constructor(private readonly _http: HttpClient) {}

	findAllByCodigoViaTransporteAndEstado(estado: boolean, codigoViaTransporte: string): Observable<TbTransportista[]> {
		return this._http.get<ApiResponse<TbTransportista[]>>(`${this._url}/model-se-ro/findAllByCodigoViaTransporteAndEstado/${codigoViaTransporte}/${estado}`).pipe(map((res) => res.data));
	}

	getSelectList(codigoViaTransporte: string, saveInMemory = true, typeEntidad?: typeEntidad): Observable<NgSelectOption<TbTransportista>[] | NgSelectOption<TbEntidad>[]> {
		this._dataSelect$ = undefined;
		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByCodigoViaTransporteAndEstado(true, codigoViaTransporte).pipe(
				map((data) =>
					data.map((item) =>
						typeEntidad === 'tbEntidad'
							? {
									...item.tbEntidad,
									value: item.tbEntidad?.idEntidad,
									label: `${item.tbEntidad?.nroDocumento} - ${item.tbEntidad?.razonSocial}`,
							  }
							: {
									...item,
									value: item.idTransportista,
									label: `${item.tbEntidad?.nroDocumento} - ${item.tbEntidad?.razonSocial}`,
							  }
					)
				),
				shareReplay(1)
			);
		return this._dataSelect$;
	}

	findAllByIdList(ids: number[]): Observable<TbTransportista[]> {
		return this._http.post<ApiResponse<TbTransportista[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
