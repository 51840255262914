import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbTipoBulto } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class TbTipoBultoService extends GenericService<TbTipoBulto, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbtipobultos`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbTipoBulto[]> {
		return this._http.post<ApiResponse<TbTipoBulto[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbTipoBulto[]> {
		return this._http.get<ApiResponse<TbTipoBulto[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(estado: boolean): Observable<INgSelectObject<TbTipoBulto>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => res.map((e) => this.convertToNgSelect(e)).sort((a, b) => ((a.codigo ?? 0) > (b.codigo ?? 0) ? 1 : -1))),
			toArray()
		);
	}

	convertToNgSelect(res: TbTipoBulto | null): INgSelectObject<TbTipoBulto> {
		return {
			...res,
			value: res?.idTipoBulto,
			label: `${res?.codigo} - ${res?.nombre}`,
		};
	}
}
