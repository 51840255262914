<kt-portlet autocomplete="off">
	<kt-portlet-header icon="fas fa-ruler">
		<ng-container ktPortletTitle>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Dimensiones</strong>
			</h3>
			&nbsp;
			<span class="small pt-2 ps-1"> Listado </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body>
		<div class="container p-2">
			<div class="row" [formGroup]="formGroup">
				<div class="col-11">
					<div class="row">
						<div class="col-lg-2">
							<input-number #cantidadBulto [decimals]="0" formControlName="cantidadBulto" [required]="true" [next]="largoBulto" label="Bultos"> </input-number>
						</div>
						<div class="col-lg-2">
							<input-number #largoBulto [decimals]="3" formControlName="largoBulto" [required]="true" [next]="anchoBulto" [back]="cantidadBulto" label="Largo"> </input-number>
						</div>
						<div class="col-lg-2">
							<input-number #anchoBulto [decimals]="3" formControlName="anchoBulto" [required]="true" [next]="altoBulto" [back]="largoBulto" label="Ancho"> </input-number>
						</div>
						<div class="col-lg-2">
							<input-number #altoBulto [decimals]="3" formControlName="altoBulto" [required]="true" [next]="tbUnidadMedida" [back]="anchoBulto" label="Alto"> </input-number>
						</div>
						<div class="col-lg-2">
							<ng-select-simple
								#tbUnidadMedida
								[items]="tbUnidadMedida$"
								[virtualScroll]="false"
								formControlName="tbUnidadMedida"
								[required]="true"
								[next]="pesoBulto"
								[back]="altoBulto"
								label="Unidad">
							</ng-select-simple>
						</div>
						<div class="col-lg-2">
							<input-number #pesoBulto [decimals]="3" formControlName="pesoBulto" [next]="agregar" [back]="tbUnidadMedida" label="Peso bulto"> </input-number>
						</div>
					</div>
				</div>
				<div class="col-lg-1">
					<div class="container-button">
						<sc-button #agregar size="sm" color="warning" [fill]="true" [matTooltip]="'Agregar'" matTooltipClass="bg-warning" height="31.5px" width="34px" (onClick)="addRow()">
							<em class="fas fa-plus px-1"></em>
						</sc-button>
					</div>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-md-12 col-lg-12 col-xl-12">
					<ag-grid-simple
						(onGridReady)="onGridReady($event[0], $event[1])"
						[frameworkComponents]="frameWorkComponents"
						[rowData]="rowData"
						[columnDefs]="columnDefs"
						(onDeleteRow)="deleteRow($event)"
						[height]="'170px'"
						[menuActions]="true"
						(onCellStopEditing)="cellEditingStopped($any($event))">
					</ag-grid-simple>
				</div>
			</div>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row">
			<div class="col-lg-7 d-flex align-items-center">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-lg-5 d-flex justify-content-end my-auto">
				<button #aceptar type="button" [disabled]="isDisabledBtnSave()" (click)="save()" class="btn btn-success me-3">Aceptar</button>
				<button #cancelar type="button" (click)="closeDialog()" class="btn btn-danger" (keyup.ArrowLeft)="cantidadBulto.focus()">Cancelar</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
