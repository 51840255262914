<kt-portlet autocomplete="off">
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<div class="me-3">
				<em class="fas fa-tags fa-2x"></em>
			</div>
			<h3 class="kt-portlet__head-title py-1 me-1 w-100">
				<div class="d-flex justify-content-between">
					<div>
						<strong>Contenedores </strong>
						<small>Listado</small>
					</div>
				</div>
			</h3>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body>
		<div class="container p-2">
			<div class="row" [formGroup]="formGroup">
				<div class="col-lg-3">
					<input-number #cantidad [maxLength]="4" [decimals]="0" formControlName="cantidad" [required]="true" [next]="tbTipoContenedor" label="Cantidad"> </input-number>
				</div>
				<div class="col-lg-6">
					<ng-select-simple #tbTipoContenedor [items]="tbTipoContenedor$" [virtualScroll]="false" formControlName="tbTipoContenedor" [required]="true" [next]="agregar" [back]="cantidad" label="Tipo">
					</ng-select-simple>
				</div>
				<div class="col-lg-3 d-flex flex-column align-self-end">
					<button #agregar type="button" class="btn btn-warning btn-sm btn-block my-4" (click)="addRow()">Agregar</button>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 col-lg-12 col-xl-12">
					<ag-grid-simple
						(onGridReady)="onGridReady($event[0], $event[1])"
						[frameworkComponents]="frameWorkComponents"
						[rowData]="rowData"
						[columnDefs]="columnDefs"
						(onDeleteRow)="deleteRow($event)"
						[height]="'170px'"
						[menuActions]="true"
						(onCellStopEditing)="cellEditingStopped($any($event))">
					</ag-grid-simple>
				</div>
			</div>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row">
			<div class="col-lg-7 d-flex align-items-center">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-lg-5 d-flex justify-content-end my-auto">
				<button #aceptar type="button" [disabled]="isDisabledBtnSave()" (click)="save()" class="btn btn-success me-3">Aceptar</button>
				<button #cancelar type="button" (click)="closeDialog()" class="btn btn-danger" (keyup.ArrowLeft)="cantidad.focus()">Cancelar</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
