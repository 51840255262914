import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { TbTipoDestinacion } from '~models/maestros';
import { ApiResponse } from 'sumax-erp-schematics';

interface TipoDestinacionService {
	findAllBySearch(): Observable<TbTipoDestinacion[]>;
	findAllByEstado(estado: boolean): Observable<TbTipoDestinacion[]>;
}

@Injectable({
	providedIn: 'root',
})
export class TbTipoDestinacionService implements TipoDestinacionService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbtipodestinaciones`;

	constructor(private readonly _http: HttpClient) {}

	findAllBySearch(): Observable<TbTipoDestinacion[]> {
		return this._http.get<ApiResponse<TbTipoDestinacion[]>>(`${this._url}/findAllBySearch`).pipe(map((res) => res.data));
	}

	findByCodigo(codigo: string): Observable<TbTipoDestinacion> {
		return this._http.get<ApiResponse<TbTipoDestinacion>>(`${this._url}/findByCodigo/${String(codigo)}`).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbTipoDestinacion[]> {
		return this._http.get<ApiResponse<TbTipoDestinacion[]>>(`${this._url}/findAllByEstado/${String(estado)}`).pipe(map((res) => res.data));
	}
}
