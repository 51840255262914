import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { find } from 'lodash-es';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SciOficina, SciSistema } from '~models/config';
import {
	TbCliente,
	TbConcepto,
	TbEjecutivo,
	TbEntidadContacto,
	TbIncoterm,
	TbTipoEnvio,
	TbTipoManifiesto,
	TbTipoOperador,
	TbTransportista,
	TbUbicacionComercial,
	TbViaTransporte,
} from '~models/maestros';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { SacTipoEstadoCotizacion } from '~models/maestros-sync/maestros/SacTipoEstadoCotizacion';
import { TbTipoMovimientoContenedor } from '~models/maestros-sync/maestros/TbTipoMovimientoContenedor';
import { SciOficinaService } from '~shared/services/sciOficina.service';
import { TbUbicacionComercialService } from '../maestros-sync/maestros/tbUbicacionComercial.service';
import { TbEjecutivoService } from '../maestros-sync/maestros/tbEjecutivo.service';
import { TbViatransporteService } from '../maestros-sync/maestros/tbViaTransporte.service';
import { TbIncotermService } from '../maestros-sync/maestros/tbIncoterm.service';
import { TbClienteService } from '../maestros-sync/maestros/tbCliente.service';
import { TbTipoManifiestoService } from '../maestros-sync/maestros/tbTipoManifiesto.service';
import { SacCotizacionTipoOperadorService } from './sacCotizacionTipoOperador.service';
import { TbTipoOperadorService } from '../maestros-sync/maestros/tbTipoOperador.service';
import { SacTipoEstadoCotizacionService } from '../maestros-sync/acuerdos-comerciales/sacTipoEstadoCotizacion.service';
import { TbTipoMovimientoContenedorService } from '../maestros-sync/maestros/tbTipoMovimientoContenedor.service';
import { SearchSacCotizacion } from '~models/acuerdos-comerciales/custom/SearchSacCotizacion';
import { TbTipoEnvioService } from '../maestros-sync/maestros/tbTipoEnvio.service';
import { TbEntidadContactoService } from '../maestros/tbEntidadContacto.service';
import { TbTransportistaService } from '../maestros-sync/maestros/tbTransportista.service';
import { SacCotizacionConcepto } from '~models/acuerdos-comerciales';
import { SacCotizacionConceptoService } from './sacCotizacionConcepto.service';
import { TbConceptoService } from '../maestros';
import { TbMoneda } from '~models/maestros-sync/maestros';
import { TbMonedaService } from '../maestros-sync/maestros';
import { SciSistemaService } from '../maestros-sync/config/sciSistema.service';

@Injectable({
	providedIn: 'root',
})
export class SacCotizacionService extends GenericService<SacCotizacion, number> {
	private _url = `${environment.HOST_ACUERDO_COMERCIAL}/saccotizaciones`;

	constructor(
		protected _http: HttpClient,
		private sciOficinaService: SciOficinaService,
		private tbviatransporteService: TbViatransporteService,
		private tbUbicacionComercialService: TbUbicacionComercialService,
		private tbEjecutivoService: TbEjecutivoService,
		private tbIncotermService: TbIncotermService,
		private tbClienteService: TbClienteService,
		private tbTipoManifiestoService: TbTipoManifiestoService,
		private sacCotizacionTipoOperadorService: SacCotizacionTipoOperadorService,
		private sciSistemaService: SciSistemaService,
		private tbTipoOperadorService: TbTipoOperadorService,
		private sacTipoEstadoCotizacionService: SacTipoEstadoCotizacionService,
		private tbTipoMovimientoContenedorService: TbTipoMovimientoContenedorService,
		private tbTipoEnvioService: TbTipoEnvioService,
		private tbEntidadContactoService: TbEntidadContactoService,
		private tbTransportistaService: TbTransportistaService,
		private sacCotizacionConceptoService: SacCotizacionConceptoService,
		private tbConceptoService: TbConceptoService,
		private tbMonedaService: TbMonedaService
	) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findById(id: number): Observable<SacCotizacion> {
		return this._http.get<ApiResponse>(`${this._url}/model-se-ro/${id}`).pipe(
			map((response) => {
				return response.data as SacCotizacion;
			})
		);
	}

	insertSolicitudCotizacion(sacCotizacion: SacCotizacion | null): Observable<ApiResponse> {
		return this._http.post<ApiResponse>(`${this._url}/insertSolicitudCotizacion`, sacCotizacion);
	}

	deleteCotizacion(sacCotizacion: SacCotizacion): Observable<ApiResponse> {
		return this._http.delete<ApiResponse>(`${this._url}`, { body: sacCotizacion });
	}

	findListaCotizaciones(search: SearchSacCotizacion): Observable<SacCotizacion[]> {
		return this._http.post<ApiResponse<SacCotizacion[]>>(`${this._url}/model-se-ro/findListaCotizaciones`, search).pipe(
			map((res: ApiResponse<SacCotizacion[]>) => res.data),
			mergeMap((cotizacion) => this.buscarData(cotizacion)),
			map((res) => this.llenarData(res))
		);
	}

	buscarData(cotizacion: SacCotizacion[]): Observable<
		[
			SciOficina[],
			TbViaTransporte[],
			TbUbicacionComercial[],
			TbUbicacionComercial[],
			TbEjecutivo[],
			TbIncoterm[],
			TbCliente[],
			TbTipoManifiesto[],
			SacCotizacionTipoOperador[],
			TbTipoOperador[],
			SciSistema[],
			SacTipoEstadoCotizacion[],
			TbTipoMovimientoContenedor[],
			//SacCotizacionDetalle[],
			TbTipoEnvio[],
			TbEntidadContacto[],
			TbTransportista[],
			// TbUbicacionComercial[],
			// TbUbicacionComercial[],
			// SacCotizacionContenedor[],
			// TbTipoContenedor[],
			SacCotizacion[],
			SacCotizacionConcepto[],
			TbConcepto[],
			TbMoneda[]
		]
	> {
		const idCotizaciones = cotizacion.map((d) => d.idCotizacion ?? 0);
		const idsOficina = cotizacion.map((d) => d.idOficina ?? 0);
		const idsViaTransportes = cotizacion.map((d) => d.idViaTransporte ?? 0);
		const idsUbicacionComercialesEmbarque = cotizacion.map((d) => d.idUbicacionComercialEmbarque ?? 0);
		const idsUbicacionComercialesLlegadas = cotizacion.map((d) => d.idUbicacionComercialLlegada ?? 0);
		const idsEjecutivoVendedores = cotizacion.map((d) => d.idEjecutivoVendedor ?? 0);
		const idsIncoterm = cotizacion.map((d) => d.idIncoterm ?? 0);
		const idsClientes = cotizacion.map((d) => d.idCliente ?? 0);
		const idsTipoManifiestos = cotizacion.map((d) => d.idTipoManifiesto ?? 0);
		const idsTipoEstadoCotizaciones = cotizacion.map((d) => d.idTipoEstadoCotizacion ?? 0);
		const idsTipoMovimientoContenedores = cotizacion.map((d) => d.idTipoMovimientoContenedor ?? 0);
		const idsTipoEnvios = cotizacion.map((d) => d.idTipoEnvio ?? 0);
		const idsEntidadContactoClientes = cotizacion.map((d) => d.idEntidadContactoCliente ?? 0);
		const idsTransportistas = cotizacion.map((d) => d.idTransportista ?? 0);
		// const idsUbicacionComercialesDestinos = cotizacion.map((d) => d.idUbicacionComercialDestino ?? 0);
		// const idsUbicacionComercialesOrigenes = cotizacion.map((d) => d.idUbicacionComercialOrigen ?? 0);

		return forkJoin([
			this.sciOficinaService.findAllByIdList(idsOficina),
			this.tbviatransporteService.findAllByIdList(idsViaTransportes),
			this.tbUbicacionComercialService.findAllByIdList(idsUbicacionComercialesEmbarque),
			this.tbUbicacionComercialService.findAllByIdList(idsUbicacionComercialesLlegadas),
			this.tbEjecutivoService.findAllByIdList(idsEjecutivoVendedores),
			this.tbIncotermService.findAllByIdList(idsIncoterm),
			this.tbClienteService.findAllByIdList(idsClientes),
			this.tbTipoManifiestoService.findAllByIdList(idsTipoManifiestos),
			this.sacCotizacionTipoOperadorService.findAllByIdList(idCotizaciones),
			this.sacCotizacionTipoOperadorService.findAllByIdList(idCotizaciones).pipe(switchMap((list) => this.tbTipoOperadorService.findAllByIdList(list.map((e) => e.idTipoOperador ?? 0)))),
			this.sacCotizacionTipoOperadorService.findAllByIdList(idCotizaciones).pipe(switchMap((list) => this.sciSistemaService.findAllByIdList(list.map((e) => e.idSistema ?? 0)))),
			this.sacTipoEstadoCotizacionService.findAllByIdList(idsTipoEstadoCotizaciones),
			this.tbTipoMovimientoContenedorService.findAllByIdList(idsTipoMovimientoContenedores),
			//this.sacCotizacionDetalleService.findAllByIdList(idCotizaciones), //2
			this.tbTipoEnvioService.findAllByIdList(idsTipoEnvios),
			this.tbEntidadContactoService.findAllByIdList(idsEntidadContactoClientes),
			this.tbTransportistaService.findAllByIdList(idsTransportistas),
			// this.tbUbicacionComercialService.findAllByIdList(idsUbicacionComercialesDestinos),
			// this.tbUbicacionComercialService.findAllByIdList(idsUbicacionComercialesOrigenes),
			of(cotizacion),
			this.sacCotizacionConceptoService.findAllByIdList(idCotizaciones),
			this.sacCotizacionConceptoService.findAllByIdList(idCotizaciones).pipe(switchMap((list) => this.tbConceptoService.findAllByIdList(list.map((e) => e.idConcepto ?? 0)))),
			this.sacCotizacionConceptoService.findAllByIdList(idCotizaciones).pipe(switchMap((list) => this.tbMonedaService.findAllByIdList(list.map((e) => e.idMoneda ?? 0)))),
		]);
	}

	llenarData(
		res: [
			SciOficina[],
			TbViaTransporte[],
			TbUbicacionComercial[],
			TbUbicacionComercial[],
			TbEjecutivo[],
			TbIncoterm[],
			TbCliente[],
			TbTipoManifiesto[],
			SacCotizacionTipoOperador[],
			TbTipoOperador[],
			SciSistema[],
			SacTipoEstadoCotizacion[],
			TbTipoMovimientoContenedor[],
			//SacCotizacionDetalle[], //3
			TbTipoEnvio[],
			TbEntidadContacto[],
			TbTransportista[],
			// TbUbicacionComercial[],
			// TbUbicacionComercial[],
			// SacCotizacionContenedor[],
			// TbTipoContenedor[],
			SacCotizacion[],
			SacCotizacionConcepto[],
			TbConcepto[],
			TbMoneda[]
		]
	): SacCotizacion[] {
		const cotizaciones = res[16];
		const sciOficinas = res[0];
		const tbViaTransportes = res[1];
		const tbUbicacionComercialEmbarques = res[2];
		const tbUbicacionComercialLlegadas = res[3];
		const tbEjecutivoVendedores = res[4];
		const tbIncoterms = res[5];
		const tbClientes = res[6];
		const tbTipoManifiestos = res[7];
		const sacCotizacionTipoOperadores = res[8];
		const tbTipoOperadores = res[9];
		const sciSistemas = res[10];
		const sacTipoEstadoCotizaciones = res[11];
		const tbTipoMovimientoContenedores = res[12];
		const tbTipoEnvio = res[13];
		const tbEntidadContacto = res[14];
		const tbTransportistas = res[15];
		// const tbUbicacionComercialDestinos = res[16];
		// const tbUbicacionComercialOrigenes = res[17];
		const sacCotizacionConceptos = res[17];
		const tbConceptos = res[18];
		const tbMonedas = res[19];

		return cotizaciones.map((r) => {
			const sciOficina = find(sciOficinas, (t) => t.idOficina === r.idOficina);
			const tbViaTransporte = find(tbViaTransportes, (t) => t.idViaTransporte === r.idViaTransporte);
			const tbUbicacionComercialEmbarque = find(tbUbicacionComercialEmbarques, (t) => t.idUbicacionComercial === r.idUbicacionComercialEmbarque);
			const tbUbicacionComercialLlegada = find(tbUbicacionComercialLlegadas, (t) => t.idUbicacionComercial === r.idUbicacionComercialLlegada);
			const tbEjecutivoVendedor = find(tbEjecutivoVendedores, (t) => t.idEjecutivo === r.idEjecutivoVendedor);
			const tbIncoterm = find(tbIncoterms, (t) => t.idIncoterm === r.idIncoterm);
			const tbCliente = find(tbClientes, (t) => t.idCliente === r.idCliente);
			const tbTipoManifiesto = find(tbTipoManifiestos, (t) => t.idTipoManifiesto === r.idTipoManifiesto);
			const sacCotizacionTipoOperador = sacCotizacionTipoOperadores.filter((e) => e.sacCotizacion?.idCotizacion === r.idCotizacion);
			const tbTipoEnvios = find(tbTipoEnvio, (t) => t.idTipoEnvio === r.idTipoEnvio);
			const tbEntidadContactos = find(tbEntidadContacto, (t) => t.idEntidadContacto === r.idEntidadContactoCliente);
			const tbTransportista = find(tbTransportistas, (t) => t.idTransportista === r.idTransportista);
			// const tbUbicacionComercialDestino = find(tbUbicacionComercialDestinos, (t) => t.idUbicacionComercial === r.idUbicacionComercialDestino);
			// const tbUbicacionComercialOrigen = find(tbUbicacionComercialOrigenes, (t) => t.idUbicacionComercial === r.idUbicacionComercialOrigen);

			sacCotizacionTipoOperador.forEach((e) => {
				const tipoOperador = tbTipoOperadores.find((c) => e.idTipoOperador === c.idTipoOperador);
				e.tbTipoOperador = tipoOperador ?? null;
				const sciSistema = sciSistemas.find((c) => e.idSistema === c.idSistema);
				e.sciSistema = sciSistema ?? null;
			});

			sacCotizacionTipoOperador.sort((a, b) => {
				return (a.tbTipoOperador?.idTipoOperador ?? 0) - (b.tbTipoOperador?.idTipoOperador ?? 0);
			});

			const sacTipoEstadoCotizacion = find(sacTipoEstadoCotizaciones, (t) => t.idTipoEstadoCotizacion === r.idTipoEstadoCotizacion);
			const tbTipoMovimientoContenedor = find(tbTipoMovimientoContenedores, (t) => t.idTipoMovimientoContenedor === r.idTipoMovimientoContenedor);
			const sacCotizacionConcepto = sacCotizacionConceptos.filter((e) => e.sacCotizacion?.idCotizacion === r.idCotizacion);
			sacCotizacionConceptos.forEach((e) => {
				const tbConcepto = tbConceptos.find((c) => e.idConcepto === c.idConcepto);
				const tbMoneda = tbMonedas.find((c) => e.idMoneda === c.idMoneda);
				e.tbConcepto = tbConcepto ?? null;
				e.tbMoneda = tbMoneda ?? null;
			});
			if (sciOficina) r.sciOficina = sciOficina;
			if (tbViaTransporte) r.tbViaTransporte = tbViaTransporte;
			if (tbUbicacionComercialEmbarque) r.tbUbicacionComercialEmbarque = tbUbicacionComercialEmbarque;
			if (tbUbicacionComercialLlegada) r.tbUbicacionComercialLlegada = tbUbicacionComercialLlegada;
			if (tbEjecutivoVendedor) r.tbEjecutivoVendedor = tbEjecutivoVendedor;
			if (tbIncoterm) r.tbIncoterm = tbIncoterm;
			if (tbCliente) r.tbCliente = tbCliente;
			if (tbTipoManifiesto) r.tbTipoManifiesto = tbTipoManifiesto;
			if (sacCotizacionTipoOperador) r.sacCotizacionTipoOperadores = sacCotizacionTipoOperador;
			if (sacTipoEstadoCotizacion) r.sacTipoEstadoCotizacion = sacTipoEstadoCotizacion;
			if (tbTipoMovimientoContenedor) r.tbTipoMovimientoContenedor = tbTipoMovimientoContenedor;
			if (tbTipoEnvios) r.tbTipoEnvio = tbTipoEnvios;
			if (tbEntidadContactos) r.tbEntidadContactoCliente = tbEntidadContactos;
			if (tbTransportista) r.tbTransportista = tbTransportista;
			// if (tbUbicacionComercialDestino) r.tbUbicacionComercialDestino = tbUbicacionComercialDestino;
			// if (tbUbicacionComercialOrigen) r.tbUbicacionComercialOrigen = tbUbicacionComercialOrigen;
			if (sacCotizacionConcepto) r.sacCotizacionConceptos = sacCotizacionConcepto;

			return r;
		});
	}

	countByIdCotizacionAndCodUnidadMedida(idCotizacion: number, codUnidadMedida: string): Observable<number> {
		return this._http.get<ApiResponse<number>>(`${this._url}/countByIdCotizacionAndCodUnidadMedida/${idCotizacion}/${codUnidadMedida}`).pipe(map((resp) => resp.data));
	}
}
