import { Component } from '@angular/core';
import { GenericCellEditor } from '../GenericCellEditor';
import { NgSelectSimpleConfigEditor } from '~models/components/BaseConfigEditor';

let nextId = 0;
@Component({
	selector: 'ng-select-simple-editor',
	templateUrl: './ng-select-simple-editor.component.html',
})
export class NgSelectSimpleEditorComponent extends GenericCellEditor<NgSelectSimpleConfigEditor> {}
