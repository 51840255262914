import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { EnumRol } from '~shared/enums/EnumRol';
import { EnumSistema } from '~shared/enums/EnumSistema';

export interface Icono {
	nombre: string;
	icono: string;
	color?: string;
}

@Component({
	selector: 'app-icon-ag-grid-render',
	templateUrl: './icon-ag-grid-render.component.html',
})
export class IconAgGridRenderComponent implements AgEditorComponent {
	public icono: Icono[] = [];

	agInit(params: any): void {
		params.value.forEach((e: SacCotizacionTipoOperador) => {
			// console.log('params', e);
			this.icono.push({
				nombre: `${e.tbTipoOperador?.nombre} ${this.convertNroRefer(e)}`,
				icono: e.tbTipoOperador?.icono ?? '',
				color: e.nroReferencia ? '#5867DD' : '',
			});
		});
	}

	convertNroRefer(e: SacCotizacionTipoOperador): string {
		let res: string[] = [];
		if (e.sciSistema?.codigo === EnumSistema.SIS_CARGA) {
			res.push(`RO: ${e.nroReferencia}`);
		}
		if (e.sciSistema?.codigo === EnumSistema.SIS_ADUANAS) {
			res.push(`SO: ${e.nroReferencia}`);
		}
		return res.length > 0 ? ' | ' + res.join(' | ') : '';
	}

	getValue(): Icono[] {
		return this.icono;
	}
}
