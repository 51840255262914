import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SciAcceso } from '~models/config';
import { SciSistema } from '~models/maestros-sync/config';

@Injectable({
	providedIn: 'root',
})
export class SciAccesosService extends GenericService<SciAcceso, number> {
	private _url = `${environment.HOST_CONFIG}/sciaccesos`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllSciSistemaByUsuario(usuario: string): Observable<SciSistema[]> {
		return this._http.get<ApiResponse<SciSistema[]>>(`${this._url}/model-se-ro/findAllSciSistemaByUsuario/${usuario}`).pipe(map((e) => e.data));
	}

	findAllByCodigoSistemaAndIdOficina(codigoSistema: string, idOficina: number): Observable<SciFormularioAccionListado[]> {
		return this._http.get<ApiResponse<SciFormularioAccionListado[]>>(`${this._url}/model-se-ro/findAllByCodigoSistemaAndIdOficina/${codigoSistema}/${idOficina}`).pipe(map((res) => res.data));
	}
}
