import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SciOficina } from '~models/config';

@Injectable({
	providedIn: 'root',
})
export class SciOficinaService extends GenericService<SciOficina, number> {
	private _url = `${environment.HOST_CONFIG}/scioficinas`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<SciOficina[]> {
		return this._http.post<ApiResponse<SciOficina[]>>(`${this._url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
