import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-apertura-orden-dialog',
	templateUrl: './apertura-orden-dialog.component.html',
	styleUrl: './apertura-orden-dialog.component.scss',
})
export class AperturaOrdenDialogComponent {
	private _formGroup!: UntypedFormGroup;

	constructor() {}

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}
}
