/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { SdoPrinterCustom, SdoPrinterDtoProjection } from '~models/doc-oper/SdoPrinterCustom';

@Injectable({
	providedIn: 'root',
})
export class SdoPrinterService {
	private readonly _url = `${environment.HOST_DOC_OPER}/sdoprinters`;
	//private readonly _url = `http://localhost:8098/dev/sumax-erp-backend-documentos-operativos/api/sdoprinters`;

	constructor(protected readonly _http: HttpClient) {}

	generateFomatos(dto: SdoPrinterCustom[]): Observable<SdoPrinterDtoProjection[]> {
		return this._http.post<ApiResponse<SdoPrinterDtoProjection[]>>(`${this._url}/generateFormatos`, dto).pipe(map((res) => res.data));
	}
}
