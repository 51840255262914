import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ConfigService, GenericService, ApiResponse } from 'sumax-erp-schematics';
import { TbUnidadMedida } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbUnidadMedidadService extends GenericService<TbUnidadMedida, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbunidadmedidas`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByEstado(estado: boolean): Observable<TbUnidadMedida[]> {
		return this._http.get<ApiResponse<TbUnidadMedida[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}
	getNgSelect(): Observable<INgSelectObject<TbUnidadMedida>[]> {
		return this.findAllByEstado(true).pipe(
			mergeMap((d: TbUnidadMedida[]) => {
				return d.map((res: TbUnidadMedida) => {
					return {
						...res,
						value: res.idUnidadMedida,
						label: `${res.codigo} - ${res.nombre}`,
					};
				});
			}),
			toArray(),
			map((res) =>
				[...res].sort((a, b) => {
					if (a.label < b.label) return -1;
					return a > b ? 1 : 0;
				})
			)
		);
	}
	convertToNgSelect(res: TbUnidadMedida | null): INgSelectObject<TbUnidadMedida> {
		if (!res) {
			return {
				value: null,
				label: '',
				idUnidadMedida: null,
				codigo: '',
				nombre: '',
				estado: false,
			};
		}

		return {
			...res,
			value: res?.idUnidadMedida,
			label: `${res?.codigo} - ${res?.nombre}`,
		};
	}

	findAllByIdList(ids: number[]): Observable<TbUnidadMedida[]> {
		return this._http.post<ApiResponse<TbUnidadMedida[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	getSelectList(): Observable<NgSelectOption<TbUnidadMedida>[]> {
		return this.findAllByEstado(true).pipe(
			map((data) => {
				return data.map((item) => {
					return {
						...item,
						value: item.idUnidadMedida,
						label: `${item.codigo} - ${item.nombre}`,
					};
				});
			})
		);
	}

	getSelect(item: TbUnidadMedida | null | undefined): NgSelectOption<TbUnidadMedida> | null {
		return item
			? {
					...item,
					value: item.idUnidadMedida,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}
}
