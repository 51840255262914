import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbViaTransporte } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class TbViatransporteService extends GenericService<TbViaTransporte, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbviatransportes`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbViaTransporte[]> {
		return this._http.post<ApiResponse<TbViaTransporte[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbViaTransporte[]> {
		return this._http.get<ApiResponse<TbViaTransporte[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(estado: boolean): Observable<INgSelectObject<TbViaTransporte>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => {
				return of(
					res
						.map((e) => this.convertToNgSelect(e))
						.sort((a, b) => {
							const codigoA = a.codigo ? +a.codigo : 0;
							const codigoB = b.codigo ? +b.codigo : 0;
							return codigoA - codigoB;
						})
				);
			})
		);
	}

	convertToNgSelect(res: TbViaTransporte): INgSelectObject<TbViaTransporte> {
		return {
			...res,
			value: res.idViaTransporte,
			label: `${res.codigo} - ${res.nombre}`,
		};
	}
}
