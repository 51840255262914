import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { selectNroDocumento } from '@core/store/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	nroDocumento!: string;
	newNotification$: Subject<SacCotizacion> = new Subject();

	constructor(private mqttService: MqttService, private store: Store<AppState>) {
		this.store
			.select(selectNroDocumento)
			.pipe(take(1))
			.subscribe((nroDocumento) => {
				this.nroDocumento = String(nroDocumento);
			});

		this.getNewNotification();
	}

	getUpdatedCotizacionList(): Observable<[IMqttMessage, IMqttMessage]> {
		return combineLatest([this.mqttService.observe(this.nroDocumento + '- ListadoCotizacion-Estado'), this.mqttService.observe(this.nroDocumento + '- ListadoCotizacion-TipoOperador')]);
	}

	getNewNotification(): void {
		this.mqttService.observe(this.nroDocumento + '- RegistroCotizacion').subscribe((data) => {
			const dataResponseMqtt: SacCotizacion = JSON.parse(data.payload.toString()) as SacCotizacion;
			const audio = new Audio('./assets/audio/notification.mp3');
			audio.play();
			const resp = audio.play();
			if (resp !== undefined) {
				resp.catch((error) => {
					// eslint-disable-next-line no-console
					console.error(error);
				});
			}
			this.newNotification$.next(dataResponseMqtt);
		});
	}

	getUpdatedEstadoCotizacion(): Observable<IMqttMessage> {
		return this.mqttService.observe(this.nroDocumento + '- ListadoCotizacion-Estado');
	}
}
