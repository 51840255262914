<kt-portlet autocomplete="off">
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<div class="me-3">
				<em class="fa fa-user fa-2x"></em>
			</div>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Contacto</strong>
			</h3>
			<span class="small pt-2 ps-1"> Registro </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<form [formGroup]="formGroup" autocomplete="off">
			<div class="row">
				<div class="col-lg-12">
					<input-text #correoElectronico [next]="nombre" [format]="'m'" [required]="true" label="Correo electrónico" (focusout)="fillUserFields()" formControlName="correoElectronico"> </input-text>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<input-text #nombre [back]="correoElectronico" [next]="apellido" [required]="true" label="Nombre" formControlName="nombre"> </input-text>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<input-text #apellido [back]="nombre" [next]="telefono" [required]="true" label="Apellidos" formControlName="apellido"> </input-text>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<input-text #telefono [back]="apellido" [next]="aceptar" label="Número de teléfono" formControlName="telefono"> </input-text>
				</div>
			</div>
		</form>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row justify-content-md-between px-2">
			<div class="col-auto d-flex align-items-center ps-0">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-auto d-flex align-items-center flex-wrap my-auto">
				<button #aceptar type="button" class="btn btn-success me-3" (click)="onSave()">Guardar</button>
				<button #cancelar type="button" class="btn btn-danger" (click)="onCancel()">Cancelar</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
