import { Decimal } from 'decimal.js';

const regExpComma = /,/g;
const regExpDot = /./g;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toNumber(n: any): number {
	const number = Number(n);
	return number.valueOf();
}

export function formatDecimal(value: string | number, decimals: number): number {
	//Leer locale de sistema
	if (typeof value != 'undefined' && typeof decimals != 'undefined') {
		value = value.toString();
		return +Number(value.replace(regExpComma, '').replace(regExpDot, '')).toFixed(decimals);
	}
	return 0;
}

export function convertToDecimal(value: string | number, decimals = 3): string {
	if (value) {
		let numericValue: number;

		if (typeof value === 'number') {
			numericValue = value;
		} else {
			numericValue = parseFloat(value);
		}

		if (isNaN(numericValue)) {
			numericValue = 0;
		}

		return numericValue.toLocaleString(undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		});
	}

	return '0';
}

export function subtract(minuendo: number, sustraendo: number): number {
	minuendo = toNumber(minuendo);
	sustraendo = toNumber(sustraendo);
	const min = new Decimal(minuendo);
	const result = min.minus(new Decimal(sustraendo));
	return result.toNumber();
}

export function multiply(numbers: number[]): number {
	let result = new Decimal(1);
	numbers.forEach((number) => {
		const n = toNumber(number);
		if (typeof n != 'undefined') {
			result = result.mul(new Decimal(n));
		}
	});
	return result.toNumber();
}

export function divide(dividendo: number, divisor: number): number {
	dividendo = toNumber(dividendo);
	divisor = toNumber(divisor);
	if (divisor > 0 && !isNaN(dividendo) && !isNaN(divisor)) {
		const divid = new Decimal(dividendo);
		const result = divid.div(new Decimal(divisor));
		return result.toNumber();
	} else {
		return 0;
	}
}

export function parseDecimalAndCommaCustom(numero: number, decimal = 2): string {
	if (numero == null) return '';
	const d = parseFloat(String(numero)).toFixed(decimal);
	return Number(d).toLocaleString('en', {
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal,
	});
}
