<div [ngStyle]="{ width: configuration.width ?? (column?.getActualWidth() ?? 0) + 20 + 'px' }" class="position-relative row">
	<div class="col-12">
		<input-number
			(blur)="onFocusOut()"
			[formatCero]="configuration.formatCero ?? false"
			[maxLength]="configuration.maxLength"
			[showLabel]="false"
			[decimals]="configuration.decimals ?? 0"
			[label]="configuration.label"
			[required]="configuration.required ?? false"
			[formControl]="$any(formControl)"
			#control>
		</input-number>
	</div>
</div>
