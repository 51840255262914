import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbTipoManifiesto } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbTipoManifiestoService extends GenericService<TbTipoManifiesto, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbtipomanifiestos`;
	tbTipoManifiesto$: Observable<TbTipoManifiesto[]> = of([]);
	private _dataSelect$?: Observable<NgSelectOption<TbTipoManifiesto>[]>;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbTipoManifiesto[]> {
		return this._http.post<ApiResponse<TbTipoManifiesto[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbTipoManifiesto[]> {
		return this._http.get<ApiResponse<TbTipoManifiesto[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(estado: boolean): Observable<INgSelectObject<TbTipoManifiesto>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => {
				return of(
					res
						.map((e) => this.convertToNgSelect(e))
						.sort((a, b) => {
							const codigoA = a.codigo ? +a.codigo : 0;
							const codigoB = b.codigo ? +b.codigo : 0;
							return codigoA - codigoB;
						})
				);
			})
		);
	}

	convertToNgSelect(res: TbTipoManifiesto): INgSelectObject<TbTipoManifiesto> {
		return {
			...res,
			value: res.idTipoManifiesto,
			label: `${res.codigo} - ${res.nombre}`,
		};
	}

	getSelectList(saveInMemory = true, addOptionAll = false): Observable<NgSelectOption<TbTipoManifiesto>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstado(true).pipe(
				map(
					(data) =>
						data.map((item) => ({
							...item,
							value: item.idTipoManifiesto,
							label: `${item.codigo} - ${item.nombre}`,
						})) as NgSelectOption<TbTipoManifiesto>[]
				)
			);

		this._dataSelect$ = this._dataSelect$.pipe(
			map((data) => data.filter((d) => !(d.value === null && d.label === '-TODOS-'))),
			map((data) => (addOptionAll ? [{ ...(<TbTipoManifiesto>{}), value: null, label: '-TODOS-' }, ...data] : [...data])),
			shareReplay(1)
		);

		return this._dataSelect$;
	}

	getSelect(item: TbTipoManifiesto | null): NgSelectOption<TbTipoManifiesto> | null {
		return item
			? {
					...item,
					value: item.idTipoManifiesto,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}
}
