import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, forkJoin, of } from 'rxjs';
import { find, isEmpty } from 'lodash-es';
import { map, mergeMap } from 'rxjs/operators';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SacCotizacionDetalleBulto } from '~models/acuerdos-comerciales/sacCotizacionDetalleBulto';
import { TbUnidadMedidadService } from '../maestros-sync/maestros/tbUnidadMedida.service';
import { TbUnidadMedida } from '~models/maestros';

@Injectable({
	providedIn: 'root',
})
export class SacCotizacionDetalleBultoService extends GenericService<SacCotizacionDetalleBulto, number> {
	private _url = `${environment.HOST_ACUERDO_COMERCIAL}/saccotizaciondetallebultos`;

	constructor(protected _http: HttpClient, protected readonly tbUnidadMedidadService: TbUnidadMedidadService) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdCotizacion(idsCotizacionDetalle: number[]): Observable<SacCotizacionDetalleBulto[]> {
		return this._http.post<ApiResponse<SacCotizacionDetalleBulto[]>>(`${this._url}/model-se-ro/findAllByIdCotizacionDetalle`, idsCotizacionDetalle).pipe(
			map((res) => res.data),
			mergeMap((res) => {
				const idsUnidadMedida = res.map((e) => e.idUnidadMedida);
				return forkJoin([!isEmpty(idsUnidadMedida) ? this.tbUnidadMedidadService.findAllByIdList(idsUnidadMedida as number[]) : of([]), of(res)]);
			}),
			map((res) => {
				const tbUnidadesMedidas = res[0];
				const detalleBultos = res[1];
				return detalleBultos.map((d) => {
					const tbUnidadMedida = find(tbUnidadesMedidas, (c) => d.idUnidadMedida === c.idUnidadMedida);
					if (tbUnidadMedida) d.tbUnidadMedida = tbUnidadMedida;
					return d;
				});
			})
		);
	}
}
